import React from "react";

//MUI
import { Box, Avatar, Typography, Container } from "@mui/material";

//Custom Components
import HeaderTitle from "../Helper/HeaderTitle";
import { Masonry } from "@mui/lab";
import theme from "../../Theme";

import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const TestimonialTemplate = (props) => {
  const { text, name, designation } = props.data || {}; // Add a default empty object if props.data is undefined

  return (
    <React.Fragment>
      <Box
        bgcolor={theme.palette.grey[100]}
        p={3}
        borderRadius={1}
        boxShadow={4}
        display={"flex"}
        gap={2}
        flexDirection={"column"}
      >
        <Typography sx={{ fontSize: "14px", lineHeight: "24px" }}>
          {text}
        </Typography>
        <Box display={"flex"} gap={2} alignItems={"center"}>
          <Avatar alt="Remy Sharp" />
          <Box>
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "20px",
                m: 0,
                p: 0,
                fontWeight: "500",
              }}
            >
              {name}
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                lineHeight: "18px",
                fontStyle: "italic",
              }}
            >
              {designation}
            </Typography>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

const AnimatedTestimonialTemplate = ({ data, index }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 1.0, // Set the threshold to 1.0
  });
  const delay = index * 0.5; // Adjust the delay as needed

  const animationVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      exit="hidden" // Define exit animation
      variants={animationVariants}
      //transition={{ duration: 0.2, delay: 0.5 * index }}
      transition={{ duration: 0.2, delay: 0.05 * index }}
    >
      <TestimonialTemplate data={data} />
    </motion.div>
  );
};

const TestimonialBlock = ({ data }) => {
  return (
    <React.Fragment>
      <Box py={5}>
        <Container>
          <HeaderTitle
            title={"Client Testimonials"}
            highlight={"Testimonials"}
            align={"center"}
            pb={5}
          />
          <Masonry
            columns={{ xs: 1, sm: 2, md: 3 }}
            spacing={3}
            sx={{ margin: 0 }}
          >
            {data.map((item, index) => (
              //<TestimonialTemplate key={index} data={item} />
              <AnimatedTestimonialTemplate
                key={index}
                index={index}
                data={item}
              />
            ))}
          </Masonry>
        </Container>
      </Box>
    </React.Fragment>
  );
};

TestimonialTemplate.defaultProps = {
  text: "add the text",
  profilepik: "/static/images/avatar/1.jpg",
  name: "name",
  designation: "designation",
};

export default TestimonialBlock;
