import React from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Container,
} from "@mui/material";

//Custom Components
import HeaderTitle from "../Helper/HeaderTitle";

//Data
import { faqDetails } from "../../Common/data";

//Images and Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const FAQAccordion = ({ faq }) => {
  return (
    <Accordion TransitionProps={{ unmountOnExit: true }} sx={{ mb: 1 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="faq-content"
        id="faq-header"
      >
        <Typography variant="h5" py={1} px={0}>
          {faq.que}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{faq.ans}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

const AnimatedFAQAccordion = ({ faq, index }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 1.0, // Set the threshold to 1.0
  });

  const animationVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      exit="hidden" // Define exit animation
      variants={animationVariants}
      //transition={{ duration: 0.2, delay: 0.02 * index }}
      transition={{ duration: 0.2, delay: 0.05 * index }}
    >
      <FAQAccordion faq={faq} />
    </motion.div>
  );
};

const Faq = () => {
  return (
    <React.Fragment>
      <Box py={5}>
        <Container>
          <HeaderTitle
            title={"Frequently Asked Questions"}
            highlight={"Questions"}
          />
          <Typography>Your POS queries are answered here!</Typography>
          <Box pt={3}>
            {faqDetails.map((faq, index) => (
              //<FAQAccordion key={index} faq={faq} />
              <AnimatedFAQAccordion key={index} index={index} faq={faq} />
            ))}
          </Box>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default Faq;
