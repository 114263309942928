import React from "react";
import { Link } from "react-router-dom";
//MUI
import { Box, Button, Container, SvgIcon, Link as HyLink } from "@mui/material";

//Images and Icons
import { Logo, BlogIcon } from "../../Assets/Icons/Icons";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import theme from "../../Theme";

const Header = () => {
  return (
    <React.Fragment>
      <Box
        py={3}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          flexDirection: {
            xs: "column",
            md: "row",
          },
        }}
      >
        <Link to={"/"}>
          <SvgIcon component={Logo} />
        </Link>

        <Box>
          <Button
            variant="text"
            startIcon={<PhoneIphoneIcon />}
            href="tel:+919390114845"
            size="large"
            sx={{
              color: theme.palette.text.primary,
            }}
          >
            +91 93901 14845
          </Button>
          <Button
            target="_blank"
            variant="text"
            //startIcon={<BlogIcon />}
            href="https://blog.billchamppos.com/"
            size="large"
            sx={{
              color: theme.palette.text.primary,
              textTransform: "initial",
              fontWeight: "600",
            }}
          >
            Blog
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Header;
