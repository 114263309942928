import React, { useEffect } from "react";
//Router Dom
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

//MUI
import theme from "./Theme";
import { ThemeProvider, createTheme } from "@mui/material";
//Custom Components
import LinkOpenTop from "./LinkOpenTop";
//Pages
import Home from "./Pages/Home";
import TermsConditions from "./Pages/TermsConditions";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import CancellationRefundPolicy from "./Pages/CancellationRefundPolicy";
import ZoomPrevention from "./Components/Helper/ZoomPrevention";
import { ToastContainer } from 'react-toastify';


function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <LinkOpenTop />
        <ZoomPrevention />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms-and-conditions" element={<TermsConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/cancellation-and-refund-policy"
            element={<CancellationRefundPolicy />}
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
