import React, { useState, useEffect } from "react";
//MUI
import { Box, Container, Typography, Button } from "@mui/material";
import theme from "../../Theme";
//Framer
import { motion, useAnimation, transform } from "framer-motion";
//Custom Components
import HeaderTitle from "../Helper/HeaderTitle";
//Images and Icons
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
//Particles
import NewFireworks from "../Helper/Fireworks";
import axios from "axios";
const JoinCommunity = () => {
  // const totalClicks = 12500;
  const [clicks, setClicks] = useState(500);
  const [showFireworks, setShowFireworks] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const apiUrl = "https://api.billchamppos.com:9000/counters";

  useEffect(() => {
    axios
      .get(apiUrl)
      .then((response) => {
        console.log("On load: ", response);
        // Update the state with the data from the API
        if (response.data.length > 0) {
          setClicks(response.data[0].counter);
        }
      })
      .catch((error) => {
        // Handle any errors that occur during the request
        console.error("Error fetching data:", error);
      });
  }, []);

  const updateTotalClicks = () => {
    setClicks(clicks + 1);
    setShowFireworks(true);
    const api = axios.create({
      baseURL: "https://api.billchamppos.com:9000",
    });
    api
      .post("/counters", { website: "Bill Champ", counter: clicks + 1 })
      .then((response) => {
        console.log("Response here: ", response);
      })
      .catch((error) => {
        console.error("Error creating data:", error);
      });

    // After 10 seconds, set redirect to true
    setTimeout(() => {
      //setShowFireworks(false);
      setRedirect(true);
    }, 4000);
  };

  // Redirect to a new page when redirect is true
  useEffect(() => {
    if (redirect) {
      // You can use react-router-dom or window.location.href to redirect to a new page
      window.open("https://billing.billchamppos.com/register", "_blank");
    }
  }, [redirect]);
  return (
    <React.Fragment>
      <Box
      //bgcolor={theme.palette.secondary.dark}
      >
        <Box
          bgcolor={!showFireworks && theme.palette.secondary.dark}
          color={theme.palette.secondary.contrastText}
          textAlign={"center"}
          minHeight={"40vh"}
          display={"flex"}
          alignItems={"center"}
          position={"relative"}
          overflow={"hidden"}
        >
          <Container sx={{ py: 9 }}>
            <HeaderTitle
              title={"Join the amazing community of BillChamp today!"}
              highlight={"BillChamp today!"}
              align={"center"}
            />

            <Typography
              variant="h1"
              mb={0}
              pb={0}
              textAlign={"center"}
              sx={{ fontWeight: 700, letterSpacing: ".1em" }}
            >
              <motion.span key={clicks}>{clicks}</motion.span>
            </Typography>
            <Typography variant="small">
              Your POS queries are answered here!
            </Typography>
            <Box mt={5}>
              <motion.div
                whileHover={{ y: -3 }} // Moves up 2 pixels on hover
              >
                <Button
                  variant="outlined"
                  size="large"
                  startIcon={<PlayArrowIcon />}
                  sx={{
                    textTransform: "inherit",
                    color: theme.palette.primary.contrastText,
                  }}
                  onClick={updateTotalClicks}
                  disabled={showFireworks || redirect} // Disable the button when fireworks are showing
                >
                  Try for free!
                </Button>
              </motion.div>
            </Box>
          </Container>
          {showFireworks && <NewFireworks />}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default JoinCommunity;
