import React from "react";

//MUI
import { Avatar, Box, Container, Typography } from "@mui/material";
import HeaderTitle from "../Helper/HeaderTitle";

//Images and Icons
import BestFeaturesImage from "../../Assets/Images/features.png";
import MobileMockupBg from "../../Assets/Images/mockup-mobile-bg.png";

import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const BestFeaturesTemplate = (props) => {
  const { title, text, color, icon } = props.data; // Destructure the data prop
  const { direction } = props; // Destructure the direction prop

  return (
    <React.Fragment>
      <Box
        display={"flex"}
        gap={3}
        //flexDirection={direction === "reverse" ? "row-reverse" : null}
        sx={{
          flexDirection: {
            md: direction === "reverse" ? "row-reverse" : null, // For md breakpoint
            // Add more breakpoints and values as needed
          },
        }}
      >
        <Avatar sx={{ bgcolor: color, width: 60, height: 60 }}>{icon}</Avatar>
        <Box>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 600,
              textAlign: {
                md: direction === "reverse" ? "right" : null, // For md breakpoint
                // Add more breakpoints and values as needed
              },
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              lineHeight: "24px",
              textAlign: {
                md: direction === "reverse" ? "right" : null, // For md breakpoint
                // Add more breakpoints and values as needed
              },
            }}
            textAlign={direction === "reverse" ? "right" : null}
          >
            {text}
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
};

const AnimatedBestFeaturesTemplate = ({ data, index }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 1.0, // Set the threshold to 1.0
  });

  const animationVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      exit="hidden" // Define exit animation
      variants={animationVariants}
      //transition={{ duration: 0.2, delay: 0.5 * index }}
      transition={{ duration: 0.2, delay: 0.5 * index }}
    >
      <BestFeaturesTemplate data={data} />
    </motion.div>
  );
};

const BestFeatures = ({ data }) => {
  const halfLength = Math.ceil(data.length / 2);
  const firstHalf = data.slice(0, halfLength);
  const secondHalf = data.slice(halfLength);
  return (
    <React.Fragment>
      <Box py={5}>
        <Container>
          <HeaderTitle
            title={"Quick and Easy Billing with Best Features"}
            highlight={"with Best Features"}
            align={"center"}
          />
          <Typography textAlign={"center"} sx={{ textWrap: "balance" }}>
            Experience easy billing with Billchamp. Say goodbye to manual
            calculations and hello to swift, accurate transactions. Explore a
            new era in point-of-sale convenience!
          </Typography>
          <Box
            display={"flex"}
            my={7}
            sx={{
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: 4, md: 0 },
            }}
          >
            <Box
              display={"flex"}
              gap={4}
              flexDirection={"column"}
              flexBasis={"30%"}
            >
              {firstHalf.map((feature, index) => (
                //<BestFeaturesTemplate key={index} data={feature}  direction={"reverse"} />
                <AnimatedBestFeaturesTemplate
                  key={index}
                  data={feature}
                  direction={"reverse"}
                />
              ))}
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              flexBasis={"40%"}
              sx={{
                order: { xs: 3, md: 2 },
                backgroundImage: `url(${MobileMockupBg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center center",
              }}
            >
              <img
                src={BestFeaturesImage}
                style={{ margin: "0px auto", maxWidth: "100%" }}
              />
            </Box>
            <Box
              display={"flex"}
              gap={4}
              flexDirection={"column"}
              sx={{ order: { xs: 2, md: 3 } }}
              flexBasis={"30%"}
            >
              {secondHalf.map((feature, index) => (
                //<BestFeaturesTemplate key={index} data={feature} />
                <AnimatedBestFeaturesTemplate
                  key={index}
                  data={feature}
                  direction={"reverse"}
                />
              ))}
            </Box>
          </Box>
        </Container>
      </Box>
    </React.Fragment>
  );
};

BestFeaturesTemplate.defaultProps = {
  text: "add the text",
  profilepik: "/static/images/avatar/1.jpg",
  name: "name",
  designation: "designation",
};

export default BestFeatures;
