import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";

//Icons
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const FooterWhatsapp = () => {
  return (
    <React.Fragment>
      <Box sx={{ position: "fixed", zIndex: 999, bottom: 5, left: 5 }}>
        <Button
          variant="contained"
          color="success"
          startIcon={<WhatsAppIcon />}
          sx={{ textTransform: "inherit" }}
          component={Link}
          to={
            "https://wa.me/+919390114845?text=I'm%20interested%20in%20your%20POS%20Product"
          }
          target="_blank"
        >
          WhatsApp
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default FooterWhatsapp;
