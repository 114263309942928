import React, { useState } from "react";
import DefaultLayout from "../Components/Layout/DefaultLayout";

//Custom Components
import {
  Box,
  Modal,
  Button,
  TextField,
  Typography,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
} from "@mui/material";
import theme from "../Theme";
import { TermsIcon } from "../Assets/Icons/Icons";

// const newList = ({ title }) => {
//   return (
//     <React.Fragment>
//       <li>{title}</li>
//     </React.Fragment>
//   );
// };

export const ListTemp = (props) => {
  return (
    <React.Fragment>
      <List>
        <ListItem
          sx={{
            alignItems: "flex-start",
            padding: 0,
            marginTop: 0,
          }}
        >
          <div sx={{ display: "flex", alignItems: "center" }}>
            <ListItemIcon
              sx={{ minWidth: "25px", marginTop: "10px", marginRight: "0px" }}
            >
              <SvgIcon component={TermsIcon} />
            </ListItemIcon>
            <ListItemText
              sx={{
                justifyContent: "flex-start",
                "& span": { fontSize: { xs: 16, md: 18 } },
              }}
              primary={props.listData}
            />
          </div>
        </ListItem>
      </List>
    </React.Fragment>
  );
};

const TermsConditions = () => {
  return (
    <React.Fragment>
      <DefaultLayout>
        <Box mb={5}>
          <Container>
            <Box
              sx={{
                " & h4": {
                  // fontFamily: "DM Sans",
                  mb: 1,
                  fontWeight: "bold",
                  // color: theme.palette.primary.main,
                  fontSize: { xs: 16, sm: 18, lg: 22 },
                },
                " & h5": {
                  // fontFamily: "DM Sans",
                  fontWeight: "bold",
                  color: theme.palette.secondary.main,
                  fontSize: { xs: 16, sm: 18 },
                  mb: 0,
                },
                " & p": {
                  fontSize: "16px",
                  // fontFamily: "DM Sans",
                  lineHeight: 1.6,
                  marginBottom: "15px",
                  color: "#736e63",
                  fontWeight: "400",
                  fontSize: { xs: 14, sm: 16, lg: 18 },
                },
                " & ul": {
                  "& li": {
                    fontSize: "18px",
                    color: "#736e63",
                    lineHeight: 1.6,
                    fontWeight: 400,
                    // fontFamily: "DM Sans",

                    mb: 1,
                    fontSize: { xs: 14, sm: 16, lg: 17 },
                  },
                },
                " & h3": {
                  fontWeight: "bold",
                  textAlign: "center",
                  textDecoration: "underline",
                  mb: 3,
                  fontSize: { xs: 18, sm: 22, lg: 28 },
                },
                " & div ": {
                  display: "flex",
                  gap: 1,
                  alignItems: "start",
                  justifyContent: "center",
                },
                " & strong": {
                  color: "black",
                },
              }}
            >
              <Typography variant="h3">
                <u>TERMS AND CONDITIONS</u>
              </Typography>
              {/* <Typography variant="h5">Acceptance of the Terms</Typography>
              <ListTemp
                listData="To use our Services, you must be of legal age to enter into a
                binding agreement and agree to our Terms. You can accept them by
                checking a box, clicking a button, or using our Services."
              /> */}
              <Typography variant="h5">Definitions:</Typography>

              <ListTemp listData='Services" encompass business applications like the Feedback management app and Waiting queue management app, which are seamlessly integrated with BillChamp’s Point of Sale (PoS) system.' />
              <ListTemp listData='"Content" refers to the material generated and provided by BillChap in association with the Services. This includes, but is not limited to, visual interfaces, interactive features, graphics, design, compilation, computer code, products, software, functionalities, aggregate ratings, reports, and other usage-related data related to your account, as well as all other elements and components of the Services. Your Content and third-party content are excluded from this definition. Content also includes, but is not limited to, images, photos, audio, video, and various forms of information and data.' />
              <ListTemp listData='"Your content" or "User Content" signifies the content that you upload, share, or transmit through or in connection with the Services. This encompasses likes, ratings, reviews, images, photos, messages, customer information, data regarding the number of tables, table availability, table usage, and any other materials that you either present or that are displayed within your account.' />
              <Typography variant="h5">Rules of Conduct:</Typography>
              <ListTemp listData="The App is provided for your personal use only. We grant you a personal, limited, non-exclusive, and non-transferable license to access and utilize the Services strictly as allowed by these Terms." />
              <ListTemp listData="You are prohibited from using the Services for any unlawful purposes or in any manner that contradicts these Terms." />
              <ListTemp listData='When using the App, you must adhere to all relevant Indian laws and applicable international laws, collectively referred to as "Applicable Laws." ' />
              <ListTemp listData="While using the App, you commit to complying with both Applicable Laws and these Terms. Specifically, you agree not to:" />
              <ul>
                <li>
                  Employ the App in any illegal fashion or in a way that
                  encourages illegal activities, such as copyright infringement.
                </li>
                <li>
                  Attempt unauthorized access to the App or any networks,
                  servers, or computer systems connected to the App.
                </li>
                <li>
                  Modify, adapt, translate, or reverse engineer any part of the
                  App or reformat or frame any section of the App's pages,
                  except where expressly permitted by these Terms or by law.
                </li>
              </ul>
              <ListTemp listData="You undertake to indemnify BillChamp and its affiliated companies fully and immediately for any losses, damages, expenses, or costs incurred, either directly or indirectly, as a consequence of your use of the App in a manner inconsistent with these Terms or Applicable Laws." />
              <Typography variant="h5">
                Rights to Intellectual Property and Content:
              </Typography>
              <ListTemp listData="We hold exclusive copyright ownership of the Services and our Content. Additionally, we possess exclusive rights to copyrights, trademarks, service marks, logos, trade names, trade dress, and other intellectual and proprietary rights associated with the Services and Our Content. All rights are reserved." />
              <ListTemp listData="You recognize that the Services contain original works that have been created, compiled, curated, revised, selected, and organized by us and others. This involved the application of extensive time, effort, and resources, making it valuable intellectual property for us and those involved." />
              <ListTemp listData="To the maximum extent allowed by applicable law, we do not guarantee or represent that your utilization of materials presented on the Services will not infringe upon the rights of third parties who are not associated with us. You consent to promptly notify us if you become aware of any claims asserting that the Services infringe upon any copyrights, trademarks, or other contractual, intellectual, statutory, or common law rights." />
              <ListTemp listData="You also acknowledge that the Services may contain information marked as confidential by BillChamp, and you must not disclose such information without our prior written consent." />
              <ListTemp listData="Unless explicitly granted in writing, we do not confer any other express or implied rights or licenses to the Services, our Content, or our Intellectual Property Rights." />
              <ListTemp listData="By submitting Your Content, you grant us an irrevocable, non-exclusive, perpetual, royalty-free right to use Your Content for any purpose, including API partnerships with third parties, across any existing or future media. You also waive any moral rights claims or attribution against us, initiated by any third-party services or our users." />
              <ListTemp listData="You are responsible for Your Content. You represent and warrant that:" />
              <ul>
                <li>
                  Your Content was not submitted through the use of automated
                  processes like script bots.
                </li>
                <li>
                  Use of Your Content by us, third-party services, or our and
                  their third-party users will not infringe upon your rights or
                  the rights of any third party.
                </li>
                <li>
                  Your Content complies with the Terms and applicable laws.
                </li>
              </ul>
              <ListTemp listData="You assume all associated risks with Your Content, including its quality, accuracy, reliability, or any personal identification information you disclose." />
              <ListTemp listData="BillChamp may monetize some of this Content through third-party affiliate programs. However, we do not exert control over Third Party Websites and do not endorse them unless explicitly stated." />
              <ListTemp listData="Your use of third-party services will be governed by the terms of service and privacy policy of the respective third party." />
              <ListTemp listData="Third-party content does not reflect our views or those of our parent, subsidiary, affiliate companies, branches, employees, officers, directors, or shareholders." />
              <ListTemp listData="We may obtain business addresses, phone numbers, and other contact information from third-party vendors who source data from public outlets. We have no control over, and do not vouch for the accuracy, relevance, copyright compliance, legality, completeness, timeliness, or quality of any products, services, advertisements, or other content presented on or linked from the Services." />
              <ListTemp listData="We do not pre-screen or investigate third-party content before or after inclusion on our Services. We retain the right, at our discretion and without obligation, to enhance or rectify errors or omissions in any part of the content available on the Services." />
              <ListTemp listData="Where appropriate, we may, at our sole discretion and without obligation, verify updates, modifications, or changes to content on the Services. However, we are not responsible for any delays or inaccuracies related to these updates." />
              <ListTemp listData="You acknowledge and agree that BillChamp is not accountable for the availability of external sites or resources, nor do we endorse any advertising, products, or materials on those sites or resources." />
              <ListTemp listData="We assume no responsibility or liability for Your Content or any third-party content." />
              <ListTemp listData="Furthermore, you acknowledge and accept that BillChamp is not liable for any losses or damages you may incur due to the availability of such external sites or resources, or due to your reliance on the accuracy, completeness, or existence of any advertising, products, or materials on those sites or resources. In essence, we disclaim liability for any offensive, defamatory, illegal, invasive, unfair, or infringing content provided by third parties." />

              <Typography variant="h5">
                Free Trial and Subscription Charges:
              </Typography>

              <ListTemp listData="You have the option to commence your use of our App with a free trial. This trial period typically spans one week, or as otherwise specified during the sign-up process, and is designed to enable you to test the service. Your eligibility for the free trial will be communicated during the sign-up process." />
              <ListTemp listData="Following the free trial period, your yearly membership fee will be charged upfront unless you discontinue the App before the trial concludes. Please note that you will not receive notification from us when your paid membership commences." />
              <ListTemp listData="You retain the freedom to cancel your membership at any time. However, please be aware that we do not issue refunds or credits for partial membership periods." />
              <ListTemp listData="We reserve the right to modify our service plans and adjust the pricing of our Services periodically." />

              <Typography variant="h5">BillChamp Privacy Policy:</Typography>

              <ListTemp listData="We treat your privacy with the utmost seriousness. BillChamp will exclusively utilize your personal information in accordance with the provisions outlined in our privacy policy. By utilizing the App, you acknowledge and consent to having read and accepted the terms of our privacy policy in addition to these Terms." />
              <Typography variant="h5">Disclaimer and Liability:</Typography>
              <ListTemp listData='THE UTILIZATION OF THE APP IS AT YOUR OWN RISK. THE APP IS PROVIDED ON AN "AS IS" BASIS. TO THE MAXIMUM EXTENT PERMISSIBLE BY LAW: (A) BILLCHAMP DISCLAIMS ALL LIABILITY WHATSOEVER, WHETHER ARISING IN CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, CONCERNING THE APP; AND (B) ALL IMPLIED WARRANTIES, TERMS, AND CONDITIONS RELATING TO THE APP (WHETHER IMPLIED BY STATUTE, COMMON LAW, OR OTHERWISE), INCLUDING (BUT NOT LIMITED TO) ANY WARRANTY, TERM, OR CONDITION AS TO ACCURACY, COMPLETENESS, SATISFACTORY QUALITY, PERFORMANCE, FITNESS FOR PURPOSE, OR ANY SPECIAL PURPOSE, AVAILABILITY, NON-INFRINGEMENT, INFORMATION ACCURACY, AS BETWEEN BILLCHAMP AND YOU, ARE HEREBY EXCLUDED. IN PARTICULAR, BUT WITHOUT PREJUDICE TO THE FOREGOING, WE DISCLAIM ANY RESPONSIBILITY FOR ANY TECHNICAL FAILURE OF THE INTERNET AND/OR THE APP; OR ANY DAMAGE OR INJURY TO USERS OR THEIR EQUIPMENT RESULTING FROM OR RELATED TO THEIR USE OF THE APP. YOUR STATUTORY RIGHTS REMAIN UNAFFECTED." ' />
              <ListTemp listData="BillChamp shall not be held responsible, whether based on contract, tort (including, but not limited to, negligence), statutory, or any other grounds, for any of the following in connection with the App:" />

              <ul>
                <li>
                  Financial losses, which include, but are not limited to, loss
                  of income, profits, contractual agreements, business, or
                  expected savings.or
                </li>
                <li>Damage to reputation or goodwill.or</li>
                <li>Special, indirect, or consequential losses.</li>
              </ul>
              <Typography variant="h5">Service Suspension:</Typography>

              <ListTemp listData="BillChamp retains the right to suspend or cease providing any services related to the apps published by it, with or without notice, and shall have no liability or responsibility to you in any manner whatsoever if it chooses to do so." />
              <Typography variant="h5">Advertisers in the App:</Typography>
              <ListTemp listData="We assume no responsibility for advertisements contained within the App. Should you agree to purchase goods and/or services from any third party advertising within the App, you do so at your own risk. The advertiser, not BillChamp, is accountable for such goods and/or services. If you have any inquiries or complaints related to them, your sole recourse is against the advertiser." />
              <Typography variant="h5">General:</Typography>
              <ListTemp listData="These Terms (as amended from time to time) constitute the complete agreement between you and BillChamp regarding your use of the App." />
              <ListTemp listData="BillChamp reserves the right to periodically update these Terms. If such updates occur, the revised version will take immediate effect, and the current Terms can be accessed through a link in the App to this page. It is your responsibility to regularly review these Terms to stay informed of any changes, and your continued use of the App will bind you to the new policy. No other variations to these Terms will be effective unless made in writing and signed by an authorized representative on behalf of BillChamp." />
              <ListTemp listData="We will provide you with account-related information (e.g., payment authorizations, invoices, changes in password or Payment Method, confirmation messages, notices) solely in electronic form." />
              <ListTemp listData="These Terms shall be governed by and construed in accordance with Indian laws, and you consent to submit to the exclusive jurisdiction of Hyderabad, Telangana, India." />
              <ListTemp listData="If any provision(s) of these Terms is held by a court of competent jurisdiction to be invalid or unenforceable, then such provision(s) shall be construed, as nearly as possible, to reflect the intentions of the parties (as reflected in the provision(s)), and all other provisions shall remain in full force and effect." />
              <ListTemp listData="BillChamp's failure to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision unless acknowledged and agreed to by BillChamp in writing." />

              {/* <Typography variant="h5">User sign up</Typography>
              <ListTemp listData="To access and use our Services, user account registration is required. If you're representing an organization for internal use, it's advisable for all organization members to register using corporate contact details, preferably corporate email addresses. You are responsible for providing accurate, up-to-date information during sign-up and promptly updating it. Providing false or outdated information may lead to the termination of your account and access to our services. " />
              <Typography variant="h5">
                Termination of your Account and Complaints
              </Typography>

              <ListTemp listData="If we receive a complaint about your activities, we'll forward it to your primary email. You must respond within 10 days by copying us. If you don't, we may share your contact information with the complainant for legal action, assuming your consent." />

              <Typography variant="h5">
                List of Prohibited Activities
              </Typography>
              <ul>
                <li>
                  You agree not to infringe upon the intellectual property
                  rights (such as patents, trademarks, trade secrets, or
                  copyrights) of any party while offering goods or services or
                  sharing content.
                </li>
                <li>
                  You agree not to post adult content that is explicit,
                  pornographic, or obscene in nature.
                </li>
                <li>
                  You agree not to engage in unlawful activities, promote
                  discrimination or hatred based on various factors (e.g.,
                  ethnicity, race, nationality, religion, gender, sexual
                  orientation, disability, or medical condition), or incite
                  violence.
                </li>
                <li>
                  You agree not to engage in harmful actions, such as
                  defamation, abuse, harassment, bullying, stalking, threats, or
                  privacy violations, against others.
                </li>
                <li>
                  You agree not to support or promote terrorist organizations
                  while offering goods or services or sharing content.
                </li>
                <li>
                  You agree not to endorse or share content that encourages
                  self-harm or other dangerous or illegal activities.
                </li>
                <li>
                  You agree not to use the Service for selling or distributing
                  counterfeit or unauthorized goods.
                </li>
                <li>
                  You agree not to offer goods or services related to gambling,
                  including lotteries and games of chance.
                </li>
                <li>
                  You agree not to use the Service for selling or distributing
                  financial or professional services.
                </li>
                <li>
                  You agree not to use the Service for selling or distributing
                  regulated products that have varying legal statuses across
                  jurisdictions, such as e-cigarettes, firearms, explosives,
                  pharmaceuticals, medical devices, drugs, or materials for drug
                  production.
                </li>
                <li>
                  You agree not to engage in unfair, predatory, or deceptive
                  commercial practices or scams, such as pyramid schemes, click
                  fraud or get-rich-quick schemes.
                </li>
                <li>
                  You agree not to use the Service for spamming, phishing, or
                  similar activities.
                </li>
                <li>
                  You agree not to collect information about others, including
                  email addresses, without their consent.
                </li>
                <li>
                  You agree not to use the Service to link to or redirect to
                  sites involved in any of the prohibited activities mentioned
                  above
                </li>
              </ul>
              <Typography variant="h5">Data Ownership</Typography>
              <ListTemp
                listData=" We fully respect your ownership rights over the content you
                  create or store within our Services. You retain full ownership
                  of your content, and unless you provide specific permission,
                  Tektree will not use, reproduce, adapt, modify, publish, or
                  distribute your content for any commercial, marketing, or
                  similar purposes. However, it's important to note that by
                  using our Services, you grant Bill Champ the necessary
                  permissions to access, copy, distribute, store, transmit,
                  reformat, publicly display, and publicly perform your content.
                  These permissions are solely for the purpose of delivering our
                  Services to you efficiently and effectively while ensuring
                  your content remains secure and private."
              />

              <Typography variant="h5">User-Interfaced Content</Typography>
              <ListTemp
                listData="You are solely responsible for any content you transmit or
                  publish using our Services. Public content is accessible
                  online and may be indexed by search engines, so take care not
                  to accidentally make private content public. Content received
                  from others is for personal use only, and you must not exploit
                  it without permission. Respect copyright notices and copy
                  protection features. By sharing copyrighted content, you
                  affirm that you have the necessary consent from relevant
                  parties. Zoho reserves the right to block access or remove
                  content if complaints of illegality or infringement arise,
                  with Zoho's designated agent making determinations. Your use
                  of our Services implies consent to this process."
              />
              <Typography variant="h5">TradeMark</Typography>
              <ListTemp
                listData="Tektree, the logo of Tektree, and the names of individual
                  Services and their logos are trademarks of Tektree Inc. You
                  agree not to display or use, in any manner, the Tektree
                  trademarks without Tektree's prior permission."
              />
              <Typography variant="h5">Disclaimer of Warranties</Typography>
              <ListTemp
                listData=" Your use of the Services is entirely at your own risk. The
                  Services are provided as-is, without any warranties, whether
                  express or implied, including merchantability and fitness for
                  a specific purpose. Any material you download or obtain
                  through the Services is your own responsibility, and Tektree
                  disclaims any liability for damage to your devices or data. No
                  advice or information, whether written or oral, from Tektree,
                  its employees, or representatives creates any warranty beyond
                  what's explicitly stated in the Terms."
              />

              <Typography variant="h4">Privacy Policy</Typography>
              <ListTemp
                listData='We, Tektree Software Services Private Limited ("We", "Us",
                "Our", “Company”), respect the privacy of everyone who visits
                this website. The Company has created the Bill Champ POS and
                licensed its use as part of its business operations. The
                services are rendered by the Company through the Bill Champ
                Platform.'
              />

              <Typography variant="h5">Scope of our Privacy Policy</Typography>
              <ListTemp
                listData="This Privacy Policy applies to all Tektree websites linked to
                  it and extends to the products and services offered by Zoho
                  through these websites, including our mobile applications. It
                  also encompasses applications hosted by Zoho on its online
                  marketplace and those available on third-party online
                  marketplaces. Please note that this Privacy Policy does not
                  pertain to any Zoho websites, products, or services that are
                  governed by a separate privacy policy."
              />
              <ListTemp
                listData=" By submitting your Personal Information (as defined
                  hereinafter) to us and indicating your agreement with this
                  Privacy Policy, you (or anyone who accesses and uses the Bill
                  Champ Platform shall be referred to as “You”, “Your” or “User”
                  as the context may require) will be treated as having given
                  their permission, wherever necessary and appropriate, to our
                  collection, use, and disclosure of Personal Information and
                  accept this Policy along with any amendments or modifications
                  as may be made from time to time. If you do not wish to
                  provide any Personal Information, you are not entitled to use
                  any of the Bill Champ Platform or avail any of the services
                  provided on the Bill Champ Platform (“Services”). This Privacy
                  Policy is subject to our general Terms of service provided at
                  the time of signing up. (“Terms”)."
              />

              <Typography variant="h5">Information Collection</Typography>
              <ListTemp
                listData="This section addresses how Bill Champ collects and utilizes
                  information concerning website visitors, potential customers,
                  users of our products and services, and individuals who
                  contact Tektree through forms or email addresses on our
                  websites."
              />

              <Typography variant="h5">Data Processing</Typography>
              <ListTemp
                listData="This section outlines how Tektree manages the data that you
                  entrust to us when using our products and services or when
                  sharing personal or confidential information with us while
                  seeking customer support."
              />

              <Typography variant="h5">General </Typography>
              <ListTemp
                listData="Additionally, we address general matters, including Bill
                  Champ's security commitments and how we will notify you of
                  changes to this Privacy Policy."
              />

              <Typography variant="h4">Information Tektree Collects</Typography>
              <Typography variant="h5">Information You Provide:</Typography>
              <List>
                <li>
                  <strong>Account Signup : </strong> When signing up for a Zoho
                  account, you provide details such as your name, contact
                  information, email, company name, and country. You can also
                  add optional information like a photo, time zone, and
                  language. Security questions may be chosen for password
                  resets.
                </li>
                <li>
                  <strong>Event Registrations and Forms : </strong> Information
                  is recorded from event registrations, newsletter
                  subscriptions, and form submissions to download products,
                  participate in contests, respond to surveys, or request
                  customer support.
                </li>
                <li>
                  <strong>Payment Processing : </strong> Payment Processing:
                  When making purchases,Bill Champ collects your name, contact
                  information, and payment details. Credit card information is
                  stored in an encrypted format with approval for future
                  transactions.
                </li>
                <li>
                  <strong>Testimonials : </strong> If you permit Bill Champ to
                  post testimonials, your name and personal details may be
                  included. You have the option to review and edit testimonials.
                </li>
              </List>
              <Typography variant="h5">
                Information Collected Automatically:
              </Typography>
              <ul>
                <li>
                  <strong>From Browsers, Devices, and Servers : </strong>Tektree
                  collects data like IP addresses, browser type, language
                  preferences, time zone, and more from web browsers, mobile
                  devices, and servers. This information is logged to better
                  understand website visitors.
                </li>
                <li>
                  <strong>From Cookies and Tracking Technologies : </strong>
                  Temporary and permanent cookies, unique identifiers in
                  downloadable products, beacons, tags, scripts, and similar
                  tech are used to identify users, track navigation, gather
                  demographic data, and understand email campaign effectiveness.
                </li>
                <li>
                  <strong>From Application Logs and Mobile Analytics : </strong>
                  Bill Champ collects data on product and service usage,
                  including clicks, features accessed, errors, performance,
                  storage, user settings, and device information.
                </li>
              </ul>
              <Typography>
                Please note that Tektree ensures data privacy and security in
                compliance with relevant regulations.
              </Typography>
              <Typography variant="h5">Charges and Payment</Typography>
              <ul>
                <li>
                  <strong>Fees :</strong> All charges associated with Your
                  Account and use of our services (“Fees”) and any other details
                  about such Fees including payment specifics shall be mentioned
                  in an Order Form.
                </li>
                <li>
                  <strong>Payment :</strong> You hereby authorize Us or Our
                  authorized agents, as applicable, to invoice You as per the
                  terms in the Order Form. Notwithstanding anything contained
                  contrary to this Agreement, any dispute with respect to the
                  invoice should be raised within ten (10) business days from
                  the date of receipt of the invoice and shall be resolved
                  promptly. All payment transactions shall be mutually agreed
                  upon by the Parties and set forth in an Order Form.
                </li>
                <li>
                  <strong>Refunds :</strong> Unless otherwise specified in this
                  Agreement, all Fees are non-refundable. No refunds shall be
                  issued for partial use or non-use of the Services.
                </li>
                <li>
                  <strong>Late Payments/Non-payment of Fees :</strong> We will
                  notify You in the event We do not receive payment towards Fees
                  within the due date. We must receive payments within a maximum
                  of ten (10) days from the date of Our notice. If We do not
                  receive payment within the foregoing time period, in addition
                  to our right to other remedies available under law, We may (i)
                  charge an interest for late payment @ 1.5% per month and/or;
                  (ii) suspend Your access to and use of the Services until We
                  receive Your payment towards the Fees as specified herein
                  and/or; (iii) terminate Your Account.
                </li>
                <li>
                  <strong>Taxes :</strong> Taxes, as applicable per the
                  governing law of the land, shall be charged extra on such
                  Fees. Each Party shall be responsible for payment of its
                  taxes, maintaining their own books-keeping, financial records,
                  collection reports in accordance with generally accepted
                  accounting principles and be compliant to all applicable laws.
                </li>
              </ul> */}
            </Box>
          </Container>
        </Box>
      </DefaultLayout>
    </React.Fragment>
  );
};

export default TermsConditions;
