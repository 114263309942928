import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import SHA256 from "crypto-js/sha256";

//MUI
import {
  Box,
  Typography,
  Container,
  Grid,
  Button,
  Paper,
  List,
  ListItem,
} from "@mui/material";

//Custom Components
import HeaderTitle from "../Helper/HeaderTitle";

//Images and Icons
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

//Data
import { pricingDetails } from "../../Common/data";
import theme from "../../Theme";
import axios from 'axios';


const Pricing = ({ contactClick }) => {
  const [userCountry, setUserCountry] = useState(null);
  const [price, setPrice] = useState("$100(USD) Per Year"); // Default currency
  const [paymentStatus, setPaymentStatus] = useState(false);


  ///Page TITLE and Other SEO
  useEffect(() => {
    fetch("https://ipapi.co/json/")
      .then((response) => response.json())
      .then((data) => {
        const country = data.country; // Get the country from the response
        setUserCountry(country);
      })
      .catch((error) => {
        console.error("Error getting user location:", error);
      });
  }, []);

  useEffect(() => {
    console.log("Got this: ", userCountry);
    if (userCountry) {
      // Here, you can implement if-else conditions to set the selected currency
      if (userCountry === "IN") {
        setPrice("Rs. 5,999/- Per Year");
      } else if (userCountry === "GB") {
        setPrice("£80 Per Year");
      } else if (userCountry === "CA") {
        setPrice("$120 (CAD) Per Year");
      } else {
        setPrice("$100(USD) Per Year");
      }
    }
  }, [userCountry]);
  // const apiUrl = "http://localhost:5000"
  // const handlePaymentphonepay = async (e) => {
  //   e.preventDefault();
  //   const data = {
  //     name: 'Abhi',
  //     amount: "520",
  //     number: '9390114845',
  //     MUID: "MUID" + Date.now(),
  //     transactionId: 'T' + Date.now()

  //   }
  //   try {
  //     const response = await fetch(apiUrl + "/phonepe", {
  //         method: "POST",
  //         body: JSON.stringify(data),
  //         headers: {
  //             "Content-Type": "application/json",
  //         },
  //     });

  //     if (!response.ok) {
  //         throw new Error('Failed to initiate payment');
  //     }

  //     const responseData = await response.json();

  //     const redirectInfo = responseData.data.instrumentResponse.redirectInfo;
  //     console.log("responseData is the ",redirectInfo)

  //     if (redirectInfo && redirectInfo.url) {
  //         // Redirect the user to the PhonePe payment window
  //         window.location.href = redirectInfo.url;
  //     } else {
  //         throw new Error('Failed to get redirect URL');
  //     }
  // } catch (error) {
  //     console.error('There was a problem with the payment initiation:', error);
  //     // Handle error appropriately, e.g., display an error message to the user
  // }


  // }
  const makePayment = async (e) => {
    e.preventDefault();

    const transactionid = "Tr-" + uuidv4().toString(36).slice(-6);
    const payload = {
      "merchantId": "PGTESTPAYUAT",
      "merchantTransactionId": "MT7850590068188104",
      "merchantUserId": "MUID123",
      "amount": 599900,
      "redirectUrl": `https://billing.billchamppos.com/register/`,
      "redirectMode": "REDIRECT",
      "callbackUrl": `http://localhost:3001/${transactionid}`,
      "mobileNumber": "9999999999",
      "paymentInstrument": {
        "type": "PAY_PAGE"
      }
    };



    try {
      const dataPayload = JSON.stringify(payload);
      const dataBase64 = btoa(dataPayload);
      const fullURL = "/pg/v1/pay" + "099eb0cd-02cf-4e2a-8aca-3e6c6aff0399";
      const checksum = SHA256(dataBase64 + fullURL) + '###' + "1";
      const options = {
        method: 'post',
        url: 'https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
          'X-VERIFY': checksum,
        },
        data: { request: dataBase64 },
      };

      axios
        .request(options)
        .then(function (response) {
          console.log(response.data);
          setPaymentStatus(true)

          if (
            response.data &&
            response.data.success &&
            response.data.code === 'PAYMENT_INITIATED' &&
            response.data.data &&
            response.data.data.instrumentResponse &&
            response.data.data.instrumentResponse.redirectInfo &&
            response.data.data.instrumentResponse.redirectInfo.url
          ) {
            const newWindow = window.open(response.data.data.instrumentResponse.redirectInfo.url, '_blank');

            if (newWindow) {
              newWindow.focus();
            } else {
              console.error('Failed to open the new window.');
            }
          } else {
            console.error('Invalid response format or missing URL.');
          }
        })
        .catch(function (error) {
          console.error(error);
        });

    } catch (error) {
      console.error("Error making payment:", error);
    }
  };




  return (
    <React.Fragment>
      <Box py={5} bgcolor={"ButtonHighlight"}>
        <Container>
          <Grid container columnSpacing={3} rowGap={{ xs: 4, md: 1 }}>
            <Grid item xs={12} md={4}>
              <HeaderTitle
                title={"Flexible pricing plans designed to fit your business."}
                highlight={"to fit your business"}
              />
              <Typography>Upscale your business. Get started today!</Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<PlayArrowIcon />}
                sx={{ textTransform: "inherit", mt: 3 }}
                onClick={contactClick}
              >
                Contact Us
              </Button>
            </Grid>

            {pricingDetails.map((item) => (
              <Grid item xs={12} sm={6} md={4}>
                <Box>
                  <Paper sx={{ py: 5, px: 3 }}>
                    <Typography variant="h5" textAlign={"center"}>
                      {item.name}
                    </Typography>
                    <List>
                      {item.points.map((point, index) => (
                        <ListItem
                          sx={{ display: "flex", gap: "10px" }}
                          key={index}
                        >
                          <TaskAltIcon style={{ color: "#3FE059" }} />
                          {point.text}
                        </ListItem>
                      ))}
                    </List>
                    <Box>
                      {item.otherDetails.map((point, index) => (
                        <>
                          {point.price ? (
                            <Typography
                              variant="h4"
                              color={theme.palette.primary.main}
                              textAlign={"center"}
                              p={2}
                            >
                              {price}
                            </Typography>
                          ) : null}

                          {point.desc ? (
                            <Typography
                              sx={{
                                fontSize: "14px",
                                p: "20px",
                                textAlign: "center",
                              }}
                            >
                              {point.desc}
                            </Typography>
                          ) : null}

                          {point.link ? (
                            <Box display={"flex"} justifyContent={"center"}>
                              <Button
                                variant="outlined"
                                color="inherit"
                                size="large"
                                onClick={point.modal ? contactClick : makePayment}
                                sx={{
                                  textTransform: "inherit",
                                  margin: "0px auto",
                                }}
                                to={!point.modal ? point.link : null}
                                component={Link}
                                target={!point.modal ? "_blank" : null}
                              >
                                {point.linkText}
                              </Button>
                            </Box>
                          ) : null}
                          {paymentStatus && !point.modal && (
                            <Typography className="MuiTypography-root MuiTypography css-17pmoso-MuiTypography-root" style={{ fontSize: '13px' }}>
                              * This is just a test transaction, you will not be charged for it. Please contact administrator on +91-9390114845 to activate your account.
                            </Typography>
                          )}
                          {/* {paymentStatus && !point.modal ? (
                            <span className="MuiTypography-root MuiTypography css-17pmoso-MuiTypography-root" style={{ fontSize: '12px' }}>*This is just a test transaction, you will not be charged for it. Please contact administrator on +91-9390114845 to activate your account.</span>
                          ) : ""} */}

                    


                          {/* {
                            <Box display={"flex"} justifyContent={"center"}>
                              <Button
                                variant="outlined"
                                color="inherit"
                                size="large"
                                onClick={makePayment}
                                sx={{
                                  textTransform: "inherit",
                                  margin: "0px auto",
                                }}
                              // to={!point.modal ? point.link : null}
                              // component={Link}
                              // target={!point.modal ? "_blank" : null}
                              >
                                {point.linkText}
                              </Button>
                            </Box>
                          } */}
                        </>
                      ))}
                    </Box>
                  </Paper>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default Pricing;
