import React from "react";
import { Link as HyLink } from "react-router-dom";
//MUI
import {
  Box,
  Container,
  Grid,
  Link,
  SvgIcon,
  Typography,
  TextField,
  InputBase,
  Divider,
  IconButton,
  Paper,
  Button,
} from "@mui/material";
import theme from "../../Theme";

//Data
import { footerMenu, smDetails, cityLinks } from "../../Common/data";

//Custom Components
import FooterWhatsapp from "./FooterWhatsapp";

//Images and Icons
import { Logo } from "../../Assets/Icons/Icons";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";

//Animation
import { motion } from "framer-motion";
import { Masonry } from "@mui/lab";
import ScrollToTopButton from "../Helper/ScrollToTopButton";
const Footer = () => {
  return (
    <React.Fragment>
      <footer style={{ position: "relative" }}>
        <Box
          sx={{
            py: 4,
            bgcolor: theme.palette.grey[100],
          }}
        >
          <Container maxWidth={"sm"}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              gap={2}
            >
              <SvgIcon component={Logo} />
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={0.5}
              >
                <Typography
                  variant="small"
                  sx={{
                    textWrap: "balance",
                    textAlign: "center",
                    fontWeight: "600",
                  }}
                >
                  TEKTREE SOFTWARE SERVICES PRIVATE LIMITED
                </Typography>
                <Typography
                  variant="small"
                  sx={{ textWrap: "balance", textAlign: "center" }}
                >
                  Office No # 4, 4th Floor Survey No: 83/1, Dallas Centre,
                  Knowledge City, Raidurg, Hitech City, Hyderabad- 500032.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                {smDetails.map((item, index) => (
                  <motion.div
                    whileHover={{ y: -3 }} // Moves up 2 pixels on hover
                    key={index}
                  >
                    <IconButton
                      aria-label={item.text}
                      key={index}
                      component={HyLink}
                      target="_blank"
                      to={item.links}
                    >
                      {item.icon}
                    </IconButton>
                  </motion.div>
                ))}
              </Box>

              {footerMenu.map((item, index) => (
                <Box
                  key={index}
                  sx={{ mt: 1 }}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  gap={1}
                >
                  <Typography variant="h6">{item.name}</Typography>
                  <Box
                    display={"flex"}
                    sx={{
                      gap: {
                        xs: 1, // For extra-small screens
                        md: 2, // For medium-sized screens and larger},
                      },
                      flexDirection: {
                        xs: "column", // For extra-small screens
                        md: "row", // For medium-sized screens and larger
                      },
                    }}
                  >
                    {item.subMenu.map((subItem, index) => (
                      <motion.div
                        whileHover={{ y: -3 }} // Moves up 2 pixels on hover
                        key={index}
                      >
                        <Link
                          key={index}
                          component={HyLink}
                          to={subItem.links}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "13px",
                            gap: "7px",
                            justifyContent: "center",
                            textDecoration: "none",
                            "&:hover": {
                              color: theme.palette.secondary.main,
                            },
                          }}
                        >
                          {subItem.icon} {subItem.text}
                        </Link>
                      </motion.div>
                    ))}
                  </Box>
                </Box>
              ))}
            </Box>
          </Container>
        </Box>
        <Box
          bgcolor={theme.palette.text.primary}
          color={theme.palette.primary.contrastText}
          py={4}
        >
          <Container>
            <Masonry
              columns={{ xs: 1, sm: 2, md: 3 }}
              spacing={2}
              sx={{ margin: 0 }}
            >
              {cityLinks.map((city, index) => (
                <a
                  key={index}
                  href={`https://billchamppos.com/india/restaurant-pos-software-in-${city.links}`}
                  target="_blank"
                  style={{
                    color: "rgba(255,255,255,.4)",
                    textDecoration: "none",
                    fontSize: "14px",

                    textWrap: "balance",
                  }}
                >
                  Best Restaurant POS Software in {city.text}
                </a>
              ))}
            </Masonry>
          </Container>
        </Box>
      </footer>
      <ScrollToTopButton />

      <FooterWhatsapp />
    </React.Fragment>
  );
};

export default Footer;
