import React from "react";

//MUI
import {
  Avatar,
  Box,
  Container,
  Typography,
  Button,
  Grid,
  List,
  ListItem,
} from "@mui/material";

//
import HeaderTitle from "../Helper/HeaderTitle";
import CheckList from "../Helper/CheckList";

//Images and Icons
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Mockup from "../../Assets/Images/mockup.png";
import MockupBg from "../../Assets/Images/mockup-bg.png";
import MobileMockup from "../../Assets/Images/mockup-mobile.png";
import MobileMockupBg from "../../Assets/Images/mockup-mobile-bg.png";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

const ChoosingBillChamp = ({ data, contactClick }) => {
  return (
    <React.Fragment>
      <Box py={5}>
        <Container>
          <Grid container alignItems={"center"} rowGap={"40px"}>
            <Grid item xs={12} md={5}>
              <HeaderTitle
                title={"Make the smart move by choosing BillChamp"}
                highlight={"choosing BillChamp"}
              />
              <Typography>
                Say goodbye to complicated setups and welcome the ease of use,
                as our software can be up and running in just 2 minutes.
              </Typography>
              <Typography>
                Experience the benefits of accuracy, speed, and affordability
              </Typography>
              <Button
                variant="contained"
                color={"primary"}
                size="large"
                startIcon={<PlayArrowIcon />}
                sx={{ textTransform: "inherit", mt: 3 }}
                onClick={contactClick}
              >
                Contact Us Now
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              md={7}
              sx={{
                backgroundImage: `url(${MockupBg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center center",
              }}
            >
              <img src={Mockup} style={{ maxWidth: "100%" }} />
            </Grid>
          </Grid>
          <Grid container alignItems={"center"} mt={5}>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                backgroundImage: `url(${MobileMockupBg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center 100%",
                display: "flex",
                justifyContent: "center",
                "& img": {
                  maxWidth: {
                    xs: "100px",
                    md: "100%",
                  },
                },
              }}
            >
              <img src={MobileMockup} />
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>
                Explore the numerous advantages of BillChamp
              </Typography>
              <List>
                {data.map((point, index) => (
                  <ListItem
                    sx={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "flex-start",
                    }}
                    key={index}
                  >
                    <TaskAltIcon style={{ color: "#3FE059" }} />
                    <Typography>{point.text}</Typography>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default ChoosingBillChamp;
