import React, { useState } from "react";
import DefaultLayout from "../Components/Layout/DefaultLayout";

//Custom Components
import ChoosingBillChamp from "../Components/Sections/ChoosingBillChamp";
import OurPosSoftware from "../Components/Sections/OurPosSoftware";
import BestFeatures from "../Components/Sections/BestFeatures";
import TestimonialBlock from "../Components/Sections/TestimonialBlock";
import Pricing from "../Components/Sections/Pricing";
import Faq from "../Components/Sections/Faq";

//Data
import {
  choosingBillChampDetails,
  bestFeaturesDetails,
  testimonialsDetails,
  craftedBusinessDetails,
} from "../Common/data";
import {
  Box,
  Modal,
  Button,
  TextField,
  Typography,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
} from "@mui/material";
import theme from "../Theme";

import { TermsIcon } from "../Assets/Icons/Icons";

import { ListTemp } from "./TermsConditions";

const ListUnderLineTextTem = (props) => {
  const listStyle = {
    marginTop: 0,
    marginBottom: 0,
    padding: 0,
  };

  return (
    <React.Fragment>
      <List sx={listStyle}>
        <ListItem
          sx={{
            alignItems: "flex-start",
            padding: 0,
            marginTop: 0,
            marginBottom: 50,
          }}
        >
          <ListItemIcon
            sx={{ minWidth: "15px", marginTop: "10px", marginRight: "10px" }}
          >
            <SvgIcon component={TermsIcon} />
          </ListItemIcon>
          <ListItemText
            sx={{
              justifyContent: "flex-start",
            }}
            primary={
              <span>
                <span
                  style={{
                    textDecoration: "underline",
                    color: "black",
                    fontWeight: "500",
                    fontSize: "18px",
                  }}
                >
                  {props.underline}
                </span>
                {props.listData}
              </span>
            }
          />
        </ListItem>
      </List>
    </React.Fragment>
  );
};

const PrivacyPolicy = () => {
  return (
    <React.Fragment>
      <DefaultLayout>
        <Box mb={5}>
          <Container>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                " & h3": {
                  textDecoration: "underline",
                  textAlign: "center",
                  mb: 3,
                  fontSize: { xs: 20, sm: 22, md: 28, lg: 28 },
                },
                " & div ": {
                  display: "flex",
                  gap: 1,
                  alignItems: "start",

                  mb: 0.3,
                },
                " & h4": {
                  fontWeight: "450",
                  mb: 3,
                  fontSize: { xs: 18, sm: 22, lg: 28 },
                },
                " & p": {
                  fontSize: "16px",
                  // fontFamily: "DM Sans",
                  lineHeight: 1.6,
                  marginBottom: "10px",
                  color: "#736e63",
                  fontWeight: "400",
                  fontSize: { xs: 14, sm: 16, lg: 18 },
                },
                " & h5": {
                  // fontFamily: "DM Sans",
                  fontWeight: "bold",
                  color: theme.palette.secondary.main,
                  fontSize: { xs: 16, sm: 18 },
                  mb: 1.2,
                },
                " & ol": {
                  "& li": {
                    fontSize: "18px",
                    color: "#736e63",
                    lineHeight: 1.6,
                    fontWeight: 400,
                    marginLeft: "10px",
                    // fontFamily: "DM Sans",
                    listStyleType: "upper-Roman",
                    mb: 0.5,
                    fontSize: { xs: 14, sm: 18, md: 18 },
                  },
                },
                " & ul": {
                  "& li": {
                    // fontSize: "18px",
                    color: "#736e63",
                    lineHeight: 1.6,
                    fontWeight: 400,
                    // fontFamily: "DM Sans",
                    listStyleType: "disc",
                    mb: 0.5,
                    fontSize: { xs: 14, sm: 18 },
                  },
                },
                " & h4": {
                  fontWeight: 500,
                  mb: 1,
                  fontSize: { xs: 14, sm: 18, lg: 20 },
                },
                " & u": {
                  fontWeight: 500,
                  color: "black",
                },
                " & h6": {
                  fontWeight: 550,
                  fontSize: { xs: 14, sm: 16, lg: 18 },
                },
              }}
            >
              <Typography variant="h3">PRIVACY POLICY</Typography>
              <Typography variant="h4">
                Last Updated : October 12, 2023
              </Typography>
              <Typography variant="h5">BillChamp Privacy Policy:</Typography>
              <ListTemp listData='We, Tektree Software Services Private Limited ("We," "Us," "Our," “Company"), hold your privacy in high regard. This privacy policy applies to our website, desktop application, and mobile applications, collectively referred to as the “BillChamp Platforms.” The Company has developed the BillChamp Platforms and granted licenses for their use as part of our business operations.' />
              <ListTemp listData='Safeguarding your data and information and providing services to all BillChamp Platform subscribers and users is our top priority. To ensure transparency about how we collect, process, store, and use your personal data, we encourage you to review this privacy policy (hereinafter referred to as the "Policy"). This Policy is an integral part of the Terms of Service and any additional Terms of Use issued by the Company for using the BillChamp Platforms. It is fully compliant with applicable laws and regulations, including, but not limited to, the Information Technology Act, 2000, and the standards of the European Union General Data Protection Regulation (“GDPR”)."' />
              <ListTemp listData="By submitting your Personal Information (as defined hereinafter) to us and indicating your agreement with this Privacy Policy, you (or anyone who accesses and uses the BillChamp Platforms shall be referred to as “You,” “Your,” or “User” as the context may require) will be treated as having given your permission, wherever necessary and appropriate, for our collection, use, and disclosure of Personal Information. You also accept this Policy, including any amendments or modifications made from time to time. If you choose not to provide any Personal Information, you are not entitled to use the BillChamp Platforms or avail any of the services offered therein. Please note that this Privacy Policy is subject to our general Terms of Service provided at the time of signing up (“Terms”)." />

              <Typography variant="h5">Personal Information</Typography>
              <ListTemp listData="We adhere to a strict policy of collecting personal data only when it is absolutely necessary to fulfill our legal or business obligations. In certain cases, the provision of personal data may be partially required by law (e.g., tax regulations), necessary for contractual negotiations, or essential for providing a service. Failure to provide personal data could result in the inability to provide or conclude a contract or service, making it a legitimate interest for processing." />
              <ListTemp listData=" Any information gathered during your registration and subscription to BillChamp Platforms, as well as during your use of the services offered on these platforms, is considered Personal Information. Personal Information includes, but is not limited to:" />
              <ol>
                <li>Name/ Company Name</li>
                <li>Email</li>
                <li>Phone number</li>
                <li>Address</li>
                <li>
                  Billing information used when accessing our Services through
                  BillChamp Platforms.
                </li>
                <li>
                  Information provided by you during customer surveys, including
                  reviews or comments
                </li>
                <li>
                  Sensitive personal data as defined by the Information
                  Technology (Reasonable Security Practices and Procedures and
                  Sensitive Personal Data or Information) Rules, 2011, such as
                  passwords, financial data, and data identifying the gender of
                  the individual, etc.
                </li>
              </ol>
              <ListTemp listData=" We also collect information related to your usage of BillChamp Platforms, which is essential for providing you with the best possible service." />
              <ListTemp listData="During your use of the app, we may collect certain non-personal information. This may include the type of device you use to access the Services, your device's IP address, and other information related to your usage of BillChamp Platforms and Services. We are entitled to collect information regarding your usage patterns on BillChamp Platforms." />
              <ListTemp listData="We utilize both temporary and permanent cookies to obtain various types of information when you access the app, including advertisements and other content served on BillChamp Platforms. This information may encompass, but is not limited to, your web request, Internet Protocol address, browser type, browser language, the date and time of your request, and one or more cookies that uniquely identify your browser. Cookies are primarily used for authentication but may also enhance the quality of our Service by storing your preferences and tracking your usage trends." />
              <ListTemp listData="When you download or use our mobile BillChamp Platforms to access our Services, we may receive information about your location and mobile device, including a unique identifier for your device. This information may be used to provide you with Services when required. We employ mobile analytics software to gain a better understanding of our app's functionality on your device. This information may include, but is not limited to, usage frequency, events within the app, aggregated usage data, performance statistics, and the source from which the app was downloaded." />
              <ListTemp listData="Communications sent by you via email or other means are retained to process inquiries, respond to requests, and enhance our Services." />
              <ListTemp listData="We collect and store specific information automatically in log files. This may include Internet Protocol (IP) addresses, browser type, internet service provider (ISP), referring/exit pages, operating system, date/time stamp, and clickstream data." />
              <ListTemp listData="If the legal basis for processing your personal data is your consent, you have the right to withdraw your consent at any time without suffering any detriment. The withdrawal of consent does not affect the lawfulness of processing carried out prior to such withdrawal." />
              <ListTemp listData="You can withdraw your consent by contacting the Data Protection Officer, the details of whom are provided within this Privacy Policy." />

              <Typography variant="h5">Data Subject's Rights</Typography>
              <ListUnderLineTextTem
                listData="You have the right to be informed about what information is being collected and how it is being used."
                underline="Right to Information"
              />

              <ListUnderLineTextTem
                underline="Right of Access:"
                listData=" You have the right to access and receive a copy of your data.
                "
              />
              <ListUnderLineTextTem
                underline="Right to Rectification:"
                listData=" You have the right to correct any inaccurate data or complete any incomplete data."
              />
              <ListUnderLineTextTem
                underline="Right to Erasure:"
                listData="  You have the right to request the erasure of your data under the following circumstances:"
              />
              <ul>
                <li>
                  The data has been collected or processed in contravention of
                  relevant laws.
                </li>
                <li>
                  The data is no longer required for the provision of services.
                </li>
                <li>
                  You wish to withdraw your consent for the use of your data.
                </li>
                <li>
                  Such erasure is necessary for compliance with any laws in
                  force at the applicable time and jurisdiction.
                </li>
              </ul>
              <ListUnderLineTextTem
                underline="Right to Restrict Processing:"
                listData=" You have the right to request us to restrict the use of the data you have provided while continuing to retain it in our database."
              />
              <ListUnderLineTextTem
                underline="Right to data portability:"
                listData=" You have the right to request a copy of your personal data for personal use and/or to have your personal data transmitted to another party."
              />
              <ListUnderLineTextTem
                underline="Right to object to processing:"
                listData=" You have the right to
                object to the processing of Your personal data in certain
                circumstances such as for direct marketing purposes."
              />
              <ListUnderLineTextTem
                underline="Rights in relation to automated decision making and
                profiling:"
                listData=" You have the right not to be subject to a decision that is
                based on"
              />
              <ul>
                <li>
                  Automated individual decision-making - Making a decision
                  solely by automated means without any human involvement.
                </li>
                <li>
                  Profiling - Automated processing of personal data to evaluate
                  certain things about an individual.
                </li>
              </ul>
              <ListItem listData="Depending on the circumstances and the nature of your request, it may not be possible for us to fulfill your request. For example, where there is a statutory or contractual requirement for us to process your data and it would not be possible to fulfill our legal obligations if we were to stop. However, where you have consented to the processing, you can withdraw your consent at any time by emailing the Data Protection Officer. In this event, we will stop the processing as soon as such processing is no longer necessary for the provision of the services." />
              <ListTemp listData="If you want to exercise any of the rights described above or are dissatisfied with the way we have used your information, please contact our Data Protection Officer. We will seek to deal with your request without undue delay and in accordance with the requirements of the GDPR. Please note that we may keep a record of your communications to help us resolve any issues you raise." />
              <ListTemp listData="If you remain dissatisfied, you have the right to lodge a complaint with the Information Commissioner's Office through the ICO website." />

              {/* <Typography variant="h5">
                Usage of Personal Information:
              </Typography>
              <ListTemp
                listData=" We use the Personal Information and all other information
                provided by you to process your request for Services and to
                provide you the Services."
              />
              <ListTemp
                listData=" We may use the Personal Information for data analytics, either
                for you or for third parties."
              />
              <ListTemp
                listData="We use the Personal Information to enhance our Services,
                provide support to you, communicate with you and comply with
                our legal obligations, including for auditing purposes."
              />
              <ListTemp
                listData="From time to time we may share the Personal Information to
                third parties which we consider may have goods or services
                which are of interest to you. Such Personal Information are
                shared as part of the business understanding and the
                confidentiality of the Personal Information shall be protected
                at all times."
              />
              <ListTemp
                listData="We may use the Personal Information in any other manner that
                we may deem fit, in compliance to the applicable laws. We may
                also disclose Personal Information, in good faith, wherein the
                access, use, preservation or disclosure of such information is
                reasonably necessary to (a) satisfy any applicable law,
                regulation, legal process or enforceable governmental request,
                (b) enforce applicable Terms, including investigation of
                potential violations thereof, (c) detect, prevent, or
                otherwise address fraud, security or technical issues, or (d)
                protect us against imminent harm to our rights, property or
                safety, or as permitted by law or (e) in order to showcase the
                Company outreach and business portfolio, its scope and
                business volume to potential investors."
              />
              <ListTemp
                listData="If you sign up to receive email newsletters or promotional
                materials from us, we will use the information you give us to
                provide the communications you have requested. You can cancel
                email newsletters or promotional materials by selecting
                unsubscribe at the bottom of such communication. If you no
                longer wish to receive push notifications, you may turn them
                off at your device level."
              />
              <Typography variant="h5">
                Security of Your Personal Information
              </Typography>
              <ListTemp
                listData=" We endeavour to take appropriate physical, electronic, and
                managerial procedures to safeguard and help prevent
                unauthorized access to your Personal Information and to
                maintain data security. These safeguards take into account the
                sensitivity of the Personal Information that we collect,
                process and store and the current state of technology. We
                follow generally accepted industry standards to protect the
                Personal Information submitted to us, both during transmission
                and once we receive it."
              />
              <ListTemp
                listData="We host our product in a secure server environment that uses
                firewalls and other advanced technology to prevent
                interference or access from outside intruders. However, no
                method of transmission over the Internet or via mobile device,
                or method of electronic storage, is 100% secure. Therefore, we
                cannot guarantee its absolute security us."
              />
              <ListTemp
                listData="We assume no liability or responsibility for disclosure of
                your information due to errors in transmission, unauthorized
                third-party access, or other causes beyond our control. You
                play an important role in keeping your Personal Information
                secure. You should not share your user name, password, or
                other security information with anyone. If we receive
                instructions using your user name and password, we will
                consider that you have authorized the instructions. You using
                the BillChamp Platforms and accessing the Services provided by
                the Company shall mean that you have agreed to the terms of
                this Policy and no such separate agreement is required from
                your end."
              />
              <ListTemp
                listData="You acknowledge that we shall not be liable to you under any
                circumstances for any direct, indirect, punitive, incidental,
                special or consequential damages that result from or arise out
                of, or a breach or, or compromise of registration of your
                account with us and/or your ability or inability to use your
                account."
              />

              <Typography variant="h5">
                Retention of Personal Information
              </Typography>
              <ListTemp
                listData="Your personal data will not be kept for longer than is
                necessary to fulfil the specific purposes outlined in this
                Privacy Policy, and to allow Us to comply with relevant legal
                requirements."
              />
              <ListTemp
                listData="Any data We do hold onto will be anonymous. Any personally
                identifiable information such as Your name, address, date of
                birth and telephone number will be deleted after a period of
                10 years from the termination of Services."
              />
              <ListTemp
                listData="The rest of the data is therefore anonymous and will be used
                to analyse general market and website trends but without an
                identifiable personal information."
              />
              <Typography variant="h5">Transfer of Data</Typography>
              <ListTemp
                listData="We may transfer to, and store the data we collect about you
                in, countries other than the country in which the data was
                originally collected, including Singapore. Those countries may
                not have the same data protection laws as the country in which
                you provided the data. When we transfer your data to other
                countries, we will protect the data as described in this
                Privacy Policy and comply with applicable legal requirements
                providing adequate protection for the transfer of data to
                countries outside the European Economic Area (“EEA”) and
                outside the United Kingdom."
              />
              <ListTemp
                listData="If you are located in the EEA or the United Kingdom, we will
                only transfer your personal data if:"
              />
              <ul>
                <li>
                  the country to which the personal data will be transferred has
                  been granted a European Commission adequacy decision; or
                </li>
                <li>
                  we have put in place appropriate safeguards in respect of the
                  transfer, for example we have entered into EU standard
                  contractual clauses and required additional safeguards with
                  the recipient, or the recipient is a party to binding
                  corporate rules approved by an EU or UK supervisory authority.
                </li>
              </ul>
              <Typography variant="h5">
                Transfer of information to Third Parties
              </Typography>
              <ListTemp
                listData="You understand that the BillChamp Platforms not only provides
                Services directly from the Company, it also provides certain
                Services through API integration of certain third party
                service providers."
              />
              <ListTemp
                listData="You agree and acknowledge that we shall appoint certain third
                party vendors and service providers to provide such services
                in the BillChamp Platforms or facilitate the provision of
                Services in the BillChamp Platform. Whenever the BillChamp
                Platforms redirects you to the platforms / apps owned by such
                third party service providers, such third party service
                providers shall have access to your Personal Information,
                which may be recorded by such third party service providers as
                required under the applicable law. When you agree to use the
                BillChamp Platforms and subscribe to the Services provided by
                the Company through the BillChamp Platform, you understand and
                acknowledge that there are third party vendors involved in the
                marketplace services and you agree to accept the privacy
                policy provided on the website or apps or platforms of such
                third party vendors. Further, we shall not have any access to
                such personal information recorded by the third party service
                providers and thus we shall not be held liable for any breach
                of confidentiality obligations by any third party service
                providers. We are not aware of the modus operandi of such
                third party vendors individually. It is your duty to study and
                understand the privacy terms of such third party sites."
              />
              <ListTemp
                listData="The types of third parties we may share elements of your
                personal data with include, but are not limited to:"
              />
              <ul>
                <li>
                  payment processors engaged by Us to securely store or handle
                  payments information, such as credit or debit card details,
                  required for facilitating the delivery of Services
                </li>
                <li>
                  providers of data aggregation and analytics software services
                  that enable us to effectively monitor and optimise the
                  delivery of our services;
                </li>
                <li>
                  providers of software platforms that assist Us in
                  communicating or providing customer support services to You -
                  for example, We manage and respond to any messages You send to
                  Us via Our help centre using a third party communications
                  management tool;
                </li>
                <li>
                  providers of online cloud storage services and other essential
                  IT support services.
                </li>
              </ul>
              <Typography variant="h5">Third Party links</Typography>
              <ListTemp
                listData="The Services may also contain links to third-party websites.
                Your use of these features may result in the collection,
                processing or sharing of information about you, depending on
                the feature. Please be aware that we are not responsible for
                the content or privacy practices of other websites or services
                which may be linked on our services."
              />
              <ListTemp
                listData="We do not endorse or make any representations about
                third-party websites or services."
              />
              <ListTemp
                listData=" Our Privacy Policy does not cover the information you choose
                to provide to or that is collected by these third parties."
              />
              <ListTemp
                listData=" We strongly encourage you to read such third parties’ privacy
                policies"
              />
              <ListTemp
                listData="Such third party service providers may use cookies and those
                cookies may collect certain information and may be stored on
                your device when you visit our app or any other BillChamp
                Platforms."
              />
              <Typography variant="h5">
                Updating, Modifying and Deletion of Information
              </Typography>
              <ListTemp
                listData="You can update or modify or delete your Personal Information
                that is provided by you at the time of registration on our
                BillChamp Platforms by accessing your account in such
                platforms."
              />
              <ListTemp
                listData="To delete your account, You can email us at
                legal@BillChamp.com."
              />
              <ListTemp
                listData="Upon cancellation or termination of your account/subscription,
                we reserve the right to retain Personal Information as may be
                required to comply with legal obligations or our business
                model for a limited period, until such time we have the
                necessity to store your Personal Information."
              />
              <Typography variant="h5">Changes to Privacy Policy</Typography>
              <ListTemp
                listData="We reserve the right to amend or revise this Privacy Policy
                from time to time to reflect changes in the law, our data
                collection and use practices, the features of our Services, or
                advances in technology. Please check this page periodically
                for such revisions and amendments. . Any changes to this
                Privacy Policy, shall be posted in this same URL. It is your
                obligation as the user of the BillChamp Platforms to review
                the changes carefully. Your continued use of the Services and
                access of the BillChamp Platforms following the posting of
                changes to this Privacy Policy shall constitute your consent
                and acceptance of those changes."
              /> */}
              <Typography variant="h5">Governing Law:</Typography>
              <ListTemp listData="This Privacy Policy is subject to the laws of India, and any disputes will fall under the exclusive jurisdiction of the courts in Hyderabad, Telangana." />
              <Typography variant="h6">Grievance Redressal</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: { xs: "16px", sm: "32px" },
                }}
                mt={2}
              >
                <Typography variant="h6">Grievance Officer:</Typography>
                <ListTemp listData="If you have any concerns or grievances related to the App or the Services, you can reach out to the Grievance Redressal Officer, who will promptly address your issues:" />
                <Typography>Name: Mr. Mohsin</Typography>
                <Typography>Email: compliance@billchamppos.com</Typography>
                <Typography>
                  Address: Office No # 4, 4th Floor Survey No: 83/1, Dallas
                  Centre, Knowledge City, Raidurg, Hitech City, Hyderabad-
                  500032, Telangana, India
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: { xs: "16px", sm: "32px" },
                }}
                mt={2}
              >
                <Typography variant="h6">Data Protection Officer:</Typography>
                <ListTemp listData="In compliance with GDPR requirements, we have appointed a Data Protection Officer well-versed in the Company's internal data privacy policies and practices. You can contact the Data Protection Officer for any inquiries:" />
                <Typography>Name: Mr. Mohsin</Typography>
                <Typography>Email: compliance@billchamppos.com</Typography>
                <Typography>
                  Address: Office No # 4, 4th Floor Survey No: 83/1, Dallas
                  Centre, Knowledge City, Raidurg, Hitech City, Hyderabad-
                  500032.
                </Typography>
              </Box>
              <ListTemp listData="By using the App and/or the Services, you agree to the terms outlined in this Privacy Policy." />
            </Box>
          </Container>
        </Box>
      </DefaultLayout>
    </React.Fragment>
  );
};

export default PrivacyPolicy;
