import React, { useEffect } from "react";

const ZoomPrevention = () => {
  useEffect(() => {
    const handleZoom = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.deltaY) {
        // Prevent zoom on mouse wheel events with Ctrl (Windows) or Command (Mac) key
        e.preventDefault();
      }
    };

    const handleTouchZoom = (e) => {
      if (e.touches.length === 2) {
        // Prevent zoom on touch events with two fingers
        e.preventDefault();
      }
    };

    window.addEventListener("wheel", handleZoom, { passive: false });
    window.addEventListener("touchstart", handleTouchZoom, { passive: false });

    return () => {
      window.removeEventListener("wheel", handleZoom);
      window.removeEventListener("touchstart", handleTouchZoom);
    };
  }, []);

  return null;
};

export default ZoomPrevention;
