import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3F78E0",
      default: "63, 120, 224",
      ccontrastText: "#fff",
    },
    secondary: {
      main: "#343F52",
      default: "52, 63, 82",
      ccontrastText: "#fff",
    },

    text: {
      primary: "#343F52",
    },
    gray: {
      main: "#fff",
      light: "#fff",
      default: "211,220,220",
    },
  },
  typography: {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: 16,
    textWrap: "balance",

    // h1: {
    //   fontWeight: 700,
    //   fontSize: "70px",
    //   lineHeight: "140%",
    //   letterSpacing: "-0.01em",
    // },
    // h2: {
    //   fontWeight: 700,
    //   fontSize: "45px",
    //   lineHeight: "140%",
    //   letterSpacing: "-0.005em",
    // },
    h3: {
      fontSize: "30px",
      lineHeight: "1.4",
      fontWeight: 700,
      textWrap: "balance",
    },
    h4: {
      fontSize: "25px",
      lineHeight: "1.4",
      fontWeight: 600,
      textWrap: "balance",
    },
    h5: {
      fontSize: "18px",
      lineHeight: "1.4",
      fontWeight: 500,
      textWrap: "balance",
    },
    // h5: {
    //   fontSize: "36px",
    //   lineHeight: "1.4",
    //   fontWeight: 700,
    //   letterSpacing: "0.02em",
    //   "@media (max-width:600px)": {
    //     fontSize: "26px",
    //     lineHeight: "1.4",
    //     textWarp: "balance",
    //   },
    // },
    h6: {
      fontSize: "16px",
      lineHeight: "1.4",
      fontWeight: 500,
      letterSpacing: "0.05em",
      textWrap: "balance",
    },
    // subtitle1: {
    //   fontSize: "20px",
    //   lineHeight: "1.6",
    //   fontWeight: 400,
    //   letterSpacing: "0.03em",
    // },

    // //Check below

    // body1: {
    //   fontSize: "16px",
    //   fontWeight: 400,
    //   lineHeight: 1.4,
    //   letterSpacing: "0.04em",
    // },
    // body2: {},
    // body3: {
    //   fontSize: "36px",
    //   fontWeight: 700,
    //   lineHeight: "50px",
    //   letterSpacing: "0em",
    // },
    small: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "1.4",
      letterSpacing: "0em",
      textWrap: "balance",
    },
  },

  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Customize the backdrop color here
        },
      },
    },
  },
});

export default theme;
