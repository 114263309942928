import React, { Children, useEffect } from "react";

import Fireworks from "fireworks-js";
import { Box } from "@mui/material";
import theme from "../../Theme";

const NewFireworks = ({ children }) => {
  useEffect(() => {
    const container = document.getElementById("fireworks-container");
    const options = {
      maxRockets: 5,
      rocketSpawnInterval: 1500,
      numParticles: 50,
      rocketSize: 4,
      colors: ["#ff0000", "#00ff00", "#0000ff"],
      sound: true,
      soundSrc: null,
      background: "transparent", // Set background to transparent to overlay on other content
      fireworkSpeed: 2,
      fireworkAcceleration: 1.05,
      particleSpeed: 5,
      particleSize: 2,
      particleFlicker: true,
    };
    const fireworkDisplay = new Fireworks(container, options);
    fireworkDisplay.start();
  }, []);

  return (
    <Box
      bgcolor={theme.palette.secondary.dark}
      id="fireworks-container"
      sx={{
        position: "absolute", // Set position to absolute
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: -1, // Set a negative z-index to place it in the background
      }}
    >
      {/* Fireworks will be displayed in this container */}
    </Box>
  );
};

export default NewFireworks;
