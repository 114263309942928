import LinkIcon from "@mui/icons-material/Link";
import BackupIcon from "@mui/icons-material/Backup";

import {
  RestaurantIcon,
  BarsBreweriesIcon,
  FineDiningIcon,
  BakeriesIcon,
  CafeIcon,
  FastFoodIcon,
  LargeChainRestaurantsIcon,
  FoodCourtsIcon,
} from "../Assets/Icons/Icons";
import { SvgIcon } from "@mui/material";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";

const smDetails = [
  {
    text: "facebook",
    links: "https://www.facebook.com/billchamppos",
    icon: <FacebookIcon sx={{ color: "#3b5998" }} />,
    col: "#3b5998",
  },
  {
    text: "instagram",
    links: "https://www.instagram.com/billchamppos",
    icon: <InstagramIcon />,
  },
  {
    text: "linkedin",
    links: "https://www.linkedin.com/company/billchamp",
    icon: <LinkedInIcon sx={{ color: "#0072b1" }} />,
  },
  {
    text: "twitter",
    links: "https://twitter.com/billchamppos",
    icon: <TwitterIcon sx={{ color: "#00acee" }} />,
  },
  {
    text: "youtube",
    links: "https://www.youtube.com/@BillChamppos",
    icon: <YouTubeIcon sx={{ color: "#CD201F" }} />,
  },
];

const faqDetails = [
  {
    que: "What is BillChamp?",
    ans: "BillChamp is the next generation restaurant point of sale software that allows you to manage your restaurant's daily operations effectively.",
  },
  {
    que: "How to sign up for BillChamp free trial?",
    ans: "Please visit: billing.billchamppos.com and register for free. You can start using the product in as less as 2 mins!",
  },
  {
    que: "How is BillChamp different from other POS software?",
    ans: "Easy to use with next generation features that help you manage your restaurant's daily operations effectively. You can access it anywhere, anytime using any device: Desktop/Laptop, Tablet, Mobile.",
  },
  {
    que: "What are the hardware requirements for BillChamp?",
    ans: "You do not need a specialized hardware to use BillChamp as it works on any Desktop/Laptop, Tablet or Mobile device using a web browser. If you are looking for a thermal printer, our support team can guide you through the options available in the market as we do not provide the hardware.",
  },
  {
    que: "Does BillChamp integrate with Swiggy/Zomato?",
    ans: "Yes, we integrate with Swiggy/Zomato and all third party applications that has an access to the API/webhook",
  },
  {
    que: "Can BillChamp easily integrate with third-party applications and services?",
    ans: "Yes, we integrate with all third party applications that has an access to the API/webhook",
  },
  {
    que: "What are the benefits of a cloud based restaurant point of sale system?",
    ans: "The beauty of cloud-based applications lies in their ability to ensure data security and accessibility, even in the event of machine failures. With your data stored in the cloud, you can rest assured that it remains secure and intact. In case of any unforeseen issues, such as hardware failures, you'll be able to effortlessly retrieve your data with just a simple button click.",
  },
  {
    que: "How long does it take to train new employees on the POS system?",
    ans: "Our existing customers say, it takes typically 2 to 3 mins to train their new employees on the POS system. We will provide a walkthrough video tutorial and a 5 step guide to follow.",
  },
  {
    que: "Do you provide hardware for BillChamp?",
    ans: "The pricing mentioned in this website is of the software. You do not need any specialized hardware to use BillChamp as it works on any Desktop/Laptop, Tablet or Mobile device using a web browser. If you are looking for a thermal printer, our support team can guide you through the options available in the market as we do not provide the hardware.",
  },
  {
    que: "Is there any support contact number / email ID available?",
    ans: "Yes, we are available at: +91 93 90 11 48 45 or you can send us an email at: contact@billchamppos.com",
  },
];

const pricingDetails = [
  {
    name: "Regular Pricing",
    points: [
      { text: "Manage your orders easily" },
      { text: "Seamlessly integrate third-party apps" },
      { text: "Menu Management" },
      { text: "No hardware costs" },
      { text: "Affordable and Efficient" },
      { text: "Cloud-Based Software" },
    ],
    otherDetails: [
      {
        price: "5999/- Per Year",
        desc: "",
        linkText: "Buy",
        link: "https://billing.billchamppos.com/register",
        modal: false,
      },
    ],
  },
  {
    name: "Custom Pricing",
    points: [
      { text: "Manage your orders easily" },
      { text: "Seamlessly integrate third-party apps" },
      { text: "Menu Management" },
      { text: "No hardware costs" },
      { text: "Affordable and Efficient" },
      { text: "Cloud-Based Software" },
    ],
    otherDetails: [
      {
        price: "",
        desc: "Customized features for restaurants for streamlining operations well suited for their businesses!",
        linkText: "Contact Us",
        link: "/",
        modal: true,
      },
    ],
  },
];
const footerMenu = [
  {
    name: "Resources",
    subMenu: [
      {
        text: "Terms and Conditions",
        links: "/terms-and-conditions",
        icon: <LinkIcon sx={{ fontSize: "16px" }} />,
      },
      {
        text: "Privacy Policy",
        links: "/privacy-policy",
        icon: <LinkIcon sx={{ fontSize: "16px" }} />,
      },
      {
        text: "Cancellation and Refund Policy",
        links: "/cancellation-and-refund-policy",
        icon: <LinkIcon sx={{ fontSize: "16px" }} />,
      },
    ],
  },
  // {
  //   name: "Resources2",
  //   subMenu: [
  //     {
  //       text: "Terms and Conditions",
  //       link: "/terms-and-conditions",
  //       icon: <LinkIcon sx={{ fontSize: "16px" }} />,
  //     },
  //     {
  //       text: "Menu Link",
  //       link: "/",
  //       icon: <LinkIcon sx={{ fontSize: "16px" }} />,
  //     },
  //     {
  //       text: "Menu Link",
  //       link: "/",
  //       icon: <LinkIcon sx={{ fontSize: "16px" }} />,
  //     },
  //     {
  //       text: "Menu Link",
  //       link: "/",
  //       icon: <LinkIcon sx={{ fontSize: "16px" }} />,
  //     },
  //     {
  //       text: "Menu Link",
  //       link: "/",
  //       icon: <LinkIcon sx={{ fontSize: "16px" }} />,
  //     },
  //   ],
  // },
];

// const testimonialsDetails = [
//   {
//     text: "Activities like managing inventory, generating detailed reports, and billing with BillChamp are a breeze. It's fascinating how easily management and efficiency go hand in hand. It helped me cut down on errors and improve the overall customer experience.",
//     profImg: "",
//     name: "Arun Kumar",
//     designation: "Arun Kumar",
//   },
//   {
//     text: "The ease of use and reliability, combined with unmatched security, makes BillChamp the best POS software. Simplifying operations and managing them efficiently has helped our staff during peak hours, which is an advantage.",
//     profImg: "",
//     name: "Anusha Yadav",
//     designation: "Anusha Yadav",
//   },
//   {
//     text: "In an everyday, chaotic work environment, Bill Champ brings a sense of clarity to the chaos.",
//     profImg: "",
//     name: "Vishal Reddy",
//     designation: "Vishal Reddy",
//   },
//   {
//     text: "Bill Champ is the one-stop shop for all your operations and management needs. I recommend this to everyone!",
//     profImg: "",
//     name: "Syed Arbaaz",
//     designation: "Syed Arbaaz",
//   },
//   {
//     text: "I am extremely happy and satisfied with the range of services this POS software provides. It is easy to get access to every tiniest detail without any additional hardware having to be installed, which is a plus.",
//     profImg: "",
//     name: "Apoorv Mehta",
//     designation: "Apoorv Mehta",
//   },
//   {
//     text: "This POS system has transformed the direction of our sales. It is easy to track, manage, order, and use efficiently by our staff with minimal training. Bill Champ takes care of our Restaurant’s needs and necessities.",
//     profImg: "",
//     name: "Teena Roy",
//     designation: "Teena Roy",
//   },
//   {
//     text: "I can track my sales, make data driven decisions, and boost my cafe’s revenue. It seamlessly integrates into any existing devices and doesn’t require the additional cost of installing other hardware, which is not accessible to budding small businesses. Thank you,Bill Champ.",
//     profImg: "",
//     name: "Revati Naidu",
//     designation: "Revati Naidu",
//   },
//   {
//     text: "Our restaurant has had significant sales over the past few months, all thanks to Bill Champ. It was necessary for us to make this switch. I am profoundly grateful for how this POS service is customizable to suit our needs. We focus on making delicious food, while Bill Champ takes care of the rest.",
//     profImg: "",
//     name: "Apoorv Mehta",
//     designation: "Apoorv Mehta",
//   },
// ];

const testimonialsDetails = [
  {
    text: "Switching to BillChamp restaurant POS system was a game-changer for my food court.Managing orders, and online orders is a breeze now. It's user-friendly and efficient! it was a quick installation Process ",
    profImg: "",
    name: "Arun Kumar",
    designation: "Founder",
  },
  {
    text: "This restaurant management system is worth every penny.It requires no installations, is device-responsive, and works seamlessly on any device and location, thanks to its cloud-based nature. It has significantly streamlined my billing processes and enhanced customer service. I must also commend the exceptional support team for quick onboarding service!",
    profImg: "",
    name: "Anusha Yadav",
    designation: "Owner",
  },
  {
    text: "As a food court owner, I needed a cost-effective POS solution. This one fits the bill perfectly. It's simple to use and handles all aspects of my business.",
    profImg: "",
    name: "Vishal Reddy",
    designation: "Owner - Maha Food Court",
  },
  {
    text: "Managing a restaurant is tough, but BillChamp made it easier. From order processing to table management, it's all covered. Highly recommended!",
    profImg: "",
    name: "Syed Arbaaz",
    designation: "Manager",
  },
  {
    text: "I am extremely happy and satisfied with the range of services this POS software provides. It is easy to get access to every tiniest detail without any additional hardware having to be installed, which is a plus.",
    profImg: "",
    name: "Apoorv Mehta",
    designation: "Billing Manager",
  },
  {
    text: "This POS system has transformed the direction of our sales. It is easy to track, manage, order, and use efficiently by our staff with minimal training. Bill Champ takes care of our Restaurant’s needs and necessities.",
    profImg: "",
    name: "Teena Roy",
    designation: "General Manager",
  },
  {
    text: "I can track my sales, make data driven decisions, and boost my cafe’s revenue. It seamlessly integrates into any existing devices and doesn’t require the additional cost of installing other hardware, which is not accessible to budding small businesses. Thank you,Bill Champ.",
    profImg: "",
    name: "Revati Naidu",
    designation: "Assistant Manager",
  },
  {
    text: "Our restaurant has had significant sales over the past few months, all thanks to Bill Champ. It was necessary for us to make this switch. I am profoundly grateful for how this POS service is customizable to suit our needs. We focus on making delicious food, while Bill Champ takes care of the rest.",
    profImg: "",
    name: "Arjun Mehta",
    designation: "Restaurant Manager",
  },
];

const bestFeaturesDetails = [
  {
    title: "Order Management System",
    text: "Seamlessly integrate orders, streamline processes, and enhance customer satisfaction with BillChamp",
    color: "rgba(145,35,255,.2)",
    icon: <BackupIcon />,
  },
  {
    title: "Full-fledged POS",
    text: "From sales to menu management and beyond, BillChamp empowers you to run your business efficiently and profitably.",
    color: "rgba(254,30,0,.2)",
    icon: <BackupIcon />,
  },
  {
    title: "Billing System",
    text: "BillChamp streamlines your operations, helping you serve your customers better and boost your sales.",
    color: "rgba(0,128,0,.2)",
    icon: <BackupIcon />,
  },
  {
    title: "Hassle-free Zomato and Swiggy Integration",
    text: "Effortless Zomato and Swiggy Integration at Your Fingertips! Seamlessly connect your restaurant's POS system with Zomato and Swiggy to simplify online order management.",
    color: "rgba(255,124,63,.2)",
    icon: <BackupIcon />,
  },
  {
    title: "Easy Menu Management",
    text: "Our POS system empowers you to effortlessly create, update, and optimize your restaurant's menu.",
    color: "rgba(0,128,0,.2)",
    icon: <BackupIcon />,
  },
  {
    title: "Easy and safe, just use it on your phone",
    text: "Transform your mobile device into a powerful point-of-sale tool for seamless transactions.",
    color: "rgba(145,35,255,.2)",
    icon: <BackupIcon />,
  },
  {
    title: "No extra Hardware costs",
    text: "BillChamp lets you streamline your business operations without the burden of additional hardware expenses. Enjoy cost-effective solutions and maximize your efficiency.",
    color: "rgba(255,124,63,.2)",
    icon: <BackupIcon />,
  },
  {
    title: "24*7 Data Backup",
    text: "Safeguard your valuable data with BillChamp. Rest easy, knowing your business information is secure and accessible whenever you need it.",
    color: "rgba(254,30,0,.2)",
    icon: <BackupIcon />,
  },
];

const craftedBusinessDetails = [
  {
    title: "Restaurants",
    text: "BillChamp is designed to streamline restaurant operations, enhance customer service, and boost profitability.",
    icon: <SvgIcon component={RestaurantIcon} fontSize="medium" />,
  },
  {
    title: "Bars and Breweries",
    text: "From quick drink orders to easy billing, BillChamp is crafted to enhance efficiency and boost profits for bars and breweries.",
    icon: <SvgIcon component={BarsBreweriesIcon} fontSize="medium" />,
  },
  {
    title: "Large Chain Restaurants",
    text: "Empower your large-scale restaurant network with BillChamp, designed for seamless management, consistency, and growth.",
    icon: <SvgIcon component={LargeChainRestaurantsIcon} fontSize="medium" />,
  },
  {
    title: "Fine Dining and Cloud Kitchens",
    text: "Elevate the dining experience and optimize kitchen operations. Explore the future of gourmet dining and virtual kitchens with us!",
    icon: <SvgIcon component={FineDiningIcon} fontSize="medium" />,
  },
  {
    title: "Bakeries",
    text: "Elevate your bakery business with our innovative POS solution! Collect and dispatch your orders easily. ",
    icon: <SvgIcon component={BakeriesIcon} fontSize="medium" />,
  },
  {
    title: "Cafe",
    text: "Enhance your cafe's efficiency and customer satisfaction with BillChamp.",
    icon: <SvgIcon component={CafeIcon} fontSize="medium" />,
  },
  {
    title: "Food Courts",
    text: "Transform food court operations with BillChamp. Offer a seamless dining experience to your customers.",
    icon: <SvgIcon component={FoodCourtsIcon} fontSize="medium" />,
  },
  {
    title: "Fast food chains and pizzerias",
    text: "Faster billing and management for your fast food restaurant and pizzeria Business with BillChamp. Discover the future of quick-serve dining with us!",
    icon: <SvgIcon component={FastFoodIcon} fontSize="25px" />,
  },
];

const choosingBillChampDetails = [
  {
    text: "The best restaurant POS software to manage your restaurant billing, KOTs, inventory, online order, menu, and custom integrations of Swiggy, Zomato.",
  },
  {
    text: "Manage inventory across multiple outlets with ease—request, supply, and handle returns effortlessly.",
  },
  {
    text: "Our cloud-based solution empowers you to manage your restaurant from anywhere, whether it's your desktop, laptop, mobile device, or tablet.",
  },
  {
    text: "BillChamp POS streamlines your billing process with multiple terminals and a synchronized master station, improving order management efficiency.",
  },
  {
    text: "Expedite orders with quick bills to print in a snap of second and easily tailor your menu to suit your unique offerings.",
  },
  {
    text: "Effortlessly set store hours, fees, and discounts. Manage online menus across outlets and track item availability with BillChamp.",
  },
  {
    text: "Tailor your restaurant's menu with ease! Customize items, variations, and pricing with BillChamp.",
  },
  {
    text: "Easy to use, under your budget, and with no specialized hardware costs, Get BillChamp to boost and maximize efficacy. Still not convinced? Try it for yourself for 10 days and see the difference. Setup in just 5–10 minutes. Print your first bill now!",
  },
];

const footerTags = [
  "Restaurant Management",
  "Billing Software",
  "BillChamp",
  "Restaurant Technology",
  "Point of Sale (POS)",
  "Restaurant Efficiency",
  "Restaurant Software",
  "Billing System",
  "Restaurant Operations",
  "Simplified Billing",
  "BillChamp Features",
  "Restaurant Solutions",
  "Streamlined Operations",
  "Restaurant Technology Solutions",
  "Easy Billing",
  "Restaurant Automation",
  "BillChamp Benefits",
  "Restaurant POS",
  "Billing and Invoicing",
  "Restaurant Administration",
];

const cityLinks = [
  {
    text: "Adoni",
    links: "adoni",
  },
  {
    text: "Agartala",
    links: "agartala",
  },
  {
    text: "Agra",
    links: "agra",
  },
  {
    text: "Ahmedabad",
    links: "ahmedabad",
  },
  {
    text: "Ahmednagar",
    links: "ahmednagar",
  },
  {
    text: "Aizawl",
    links: "aizawl",
  },
  {
    text: "Ajmer",
    links: "ajmer",
  },
  {
    text: "Akola",
    links: "akola",
  },
  {
    text: "Alappuzha",
    links: "alappuzha",
  },
  {
    text: "Aligarh",
    links: "aligarh",
  },
  {
    text: "Allahabad",
    links: "allahabad",
  },
  {
    text: "Alwar",
    links: "alwar",
  },
  {
    text: "Amaravati",
    links: "amaravati",
  },
  {
    text: "Ambala",
    links: "ambala",
  },
  {
    text: "Ambattur",
    links: "ambattur",
  },
  {
    text: "Ambernath",
    links: "ambernath",
  },
  {
    text: "Amravati",
    links: "amravati",
  },
  {
    text: "Amritsar",
    links: "amritsar",
  },
  {
    text: "Amroha",
    links: "amroha",
  },
  {
    text: "Anand",
    links: "anand",
  },
  {
    text: "Anantapur",
    links: "anantapur",
  },
  {
    text: "Arrah",
    links: "arrah",
  },
  {
    text: "Asansol",
    links: "asansol",
  },
  {
    text: "Aurangabad",
    links: "aurangabad",
  },
  {
    text: "Avadi",
    links: "avadi",
  },
  {
    text: "Bagaha",
    links: "bagaha",
  },
  {
    text: "Baharampur",
    links: "baharampur",
  },
  {
    text: "Bahraich",
    links: "bahraich",
  },
  {
    text: "Bally",
    links: "bally",
  },
  {
    text: "Bangalore",
    links: "bangalore",
  },
  {
    text: "Baranagar",
    links: "baranagar",
  },
  {
    text: "Barasat",
    links: "barasat",
  },
  {
    text: "Bardhaman",
    links: "bardhaman",
  },
  {
    text: "Bareily",
    links: "bareily",
  },
  {
    text: "Bathinda",
    links: "bathinda",
  },
  {
    text: "Begusarai",
    links: "begusarai",
  },
  {
    text: "Belgaum",
    links: "belgaum",
  },
  {
    text: "Bellary",
    links: "bellary",
  },
  {
    text: "Berhampur",
    links: "berhampur",
  },
  {
    text: "Bettiah",
    links: "bettiah",
  },
  {
    text: "Bhagalpur",
    links: "bhagalpur",
  },
  {
    text: "Bhalswa Jahangir Pur",
    links: "bhalswa-jahangir-pur",
  },
  {
    text: "Bharatpur",
    links: "bharatpur",
  },
  {
    text: "Bhatpara",
    links: "bhatpara",
  },
  {
    text: "Bhavnagar",
    links: "bhavnagar",
  },
  {
    text: "Bhilai",
    links: "bhilai",
  },
  {
    text: "Bhilwara",
    links: "bhilwara",
  },
  {
    text: "Bhimavaram",
    links: "bhimavaram",
  },
  {
    text: "Bhind",
    links: "bhind",
  },
  {
    text: "Bhiwandi",
    links: "bhiwandi",
  },
  {
    text: "Bhiwani",
    links: "bhiwani",
  },
  {
    text: "Bhopal",
    links: "bhopal",
  },
  {
    text: "Bhubaneswar",
    links: "bhubaneswar",
  },
  {
    text: "Bhusawal",
    links: "bhusawal",
  },
  {
    text: "Bidar",
    links: "bidar",
  },
  {
    text: "Bidhan Nagar",
    links: "bidhan-nagar",
  },
  {
    text: "Bihar Sharif",
    links: "bihar-sharif",
  },
  {
    text: "Bijapur",
    links: "bijapur",
  },
  {
    text: "Bikaner",
    links: "bikaner",
  },
  {
    text: "Bilaspur",
    links: "bilaspur",
  },
  {
    text: "Bokaro",
    links: "bokaro",
  },
  {
    text: "Bulandshahr",
    links: "bulandshahr",
  },
  {
    text: "Burhanpur",
    links: "burhanpur",
  },
  {
    text: "Buxar",
    links: "buxar",
  },
  {
    text: "Chandigarh",
    links: "chandigarh",
  },
  {
    text: "Chandrapur",
    links: "chandrapur",
  },
  {
    text: "Chapra",
    links: "chapra",
  },
  {
    text: "Chennai",
    links: "chennai",
  },
  {
    text: "Chittoor",
    links: "chittoor",
  },
  {
    text: "Coimbatore",
    links: "coimbatore",
  },
  {
    text: "Cuttack",
    links: "cuttack",
  },
  {
    text: "Danapur",
    links: "danapur",
  },
  {
    text: "Darbhanga",
    links: "darbhanga",
  },
  {
    text: "Davanagere",
    links: "davanagere",
  },
  {
    text: "Dehradun",
    links: "dehradun",
  },
  {
    text: "Dehri",
    links: "dehri",
  },
  {
    text: "Delhi",
    links: "delhi",
  },
  {
    text: "Deoghar",
    links: "deoghar",
  },
  {
    text: "Dewas",
    links: "dewas",
  },
  {
    text: "Dhanbad",
    links: "dhanbad",
  },
  {
    text: "Dharmavaram",
    links: "dharmavaram",
  },
  {
    text: "Dhule",
    links: "dhule",
  },
  {
    text: "Dindigul",
    links: "dindigul",
  },
  {
    text: "Durg",
    links: "durg",
  },
  {
    text: "Durgapur",
    links: "durgapur",
  },
  {
    text: "Eluru",
    links: "eluru",
  },
  {
    text: "English Bazar",
    links: "english-bazar",
  },
  {
    text: "Erode",
    links: "erode",
  },
  {
    text: "Etawah",
    links: "etawah",
  },
  {
    text: "Faridabad",
    links: "faridabad",
  },
  {
    text: "Farrukhabad",
    links: "farrukhabad",
  },
  {
    text: "Fatehpur",
    links: "fatehpur",
  },
  {
    text: "Firozabad",
    links: "firozabad",
  },
  {
    text: "Gandhidham",
    links: "gandhidham",
  },
  {
    text: "Gandhinagar",
    links: "gandhinagar",
  },
  {
    text: "Gaya",
    links: "gaya",
  },
  {
    text: "Ghaziabad",
    links: "ghaziabad",
  },
  {
    text: "Gopalpur",
    links: "gopalpur",
  },
  {
    text: "Gorakhpur",
    links: "gorakhpur",
  },
  {
    text: "Gudivada",
    links: "gudivada",
  },
  {
    text: "Gulbarga",
    links: "gulbarga",
  },
  {
    text: "Guna",
    links: "guna",
  },
  {
    text: "Guntakal",
    links: "guntakal",
  },
  {
    text: "Guntur",
    links: "guntur",
  },
  {
    text: "Gurgaon",
    links: "gurgaon",
  },
  {
    text: "Guwahati",
    links: "guwahati",
  },
  {
    text: "Gwalior",
    links: "gwalior",
  },
  {
    text: "Hajipur",
    links: "hajipur",
  },
  {
    text: "Haldia",
    links: "haldia",
  },
  {
    text: "Hapur",
    links: "hapur",
  },
  {
    text: "Haridwar",
    links: "haridwar",
  },
  {
    text: "Hindupur",
    links: "hindupur",
  },
  {
    text: "Hospet",
    links: "hospet",
  },
  {
    text: "Howrah",
    links: "howrah",
  },
  {
    text: "Hubballi-Dharwad",
    links: "hubballi-dharwad",
  },
  {
    text: "Hugli and Chinsurah",
    links: "hugli-and-chinsurah",
  },
  {
    text: "Hyderabad",
    links: "hyderabad",
  },
  {
    text: "Ichalkaranji",
    links: "ichalkaranji",
  },
  {
    text: "Imphal",
    links: "imphal",
  },
  {
    text: "Indore",
    links: "indore",
  },
  {
    text: "Jabalpur",
    links: "jabalpur",
  },
  {
    text: "Jaipur",
    links: "jaipur",
  },
  {
    text: "Jalandhar",
    links: "jalandhar",
  },
  {
    text: "Jalgaon",
    links: "jalgaon",
  },
  {
    text: "Jalna",
    links: "jalna",
  },
  {
    text: "Jamalpur",
    links: "jamalpur",
  },
  {
    text: "Jammu",
    links: "jammu",
  },
  {
    text: "Jamnagar",
    links: "jamnagar",
  },
  {
    text: "Jamshedpur",
    links: "jamshedpur",
  },
  {
    text: "Jaunpur",
    links: "jaunpur",
  },
  {
    text: "Jehanabad",
    links: "jehanabad",
  },
  {
    text: "Jhansi",
    links: "jhansi",
  },
  {
    text: "Jodhpur",
    links: "jodhpur",
  },
  {
    text: "Junagadh",
    links: "junagadh",
  },
  {
    text: "Kadapa",
    links: "kadapa",
  },
  {
    text: "Kakinada",
    links: "kakinada",
  },
  {
    text: "Kalyan-Dombivali",
    links: "kalyan-dombivali",
  },
  {
    text: "Kamarhati",
    links: "kamarhati",
  },
  {
    text: "Kanpur",
    links: "kanpur",
  },
  {
    text: "Karaikudi",
    links: "karaikudi",
  },
  {
    text: "Karawal Nagar",
    links: "karawal-nagar",
  },
  {
    text: "Karimnagar",
    links: "karimnagar",
  },
  {
    text: "Karnal",
    links: "karnal",
  },
  {
    text: "Katihar",
    links: "katihar",
  },
  {
    text: "Kavali",
    links: "kavali",
  },
  {
    text: "Khammam",
    links: "khammam",
  },
  {
    text: "Khandwa",
    links: "khandwa",
  },
  {
    text: "Kharagpur",
    links: "kharagpur",
  },
  {
    text: "Khora",
    links: "khora",
  },
  {
    text: "Kirari Suleman Nagar",
    links: "kirari-suleman-nagar",
  },
  {
    text: "Kishanganj",
    links: "kishanganj",
  },
  {
    text: "Kochi",
    links: "kochi",
  },
  {
    text: "Kolhapur",
    links: "kolhapur",
  },
  {
    text: "Kolkata",
    links: "kolkata",
  },
  {
    text: "Kollam",
    links: "kollam",
  },
  {
    text: "Korba",
    links: "korba",
  },
  {
    text: "Kota",
    links: "kota",
  },
  {
    text: "Kottayam",
    links: "kottayam",
  },
  {
    text: "Kozhikode",
    links: "kozhikode",
  },
  {
    text: "Kulti",
    links: "kulti",
  },
  {
    text: "Kurnool",
    links: "kurnool",
  },
  {
    text: "Latur",
    links: "latur",
  },
  {
    text: "Loni",
    links: "loni",
  },
  {
    text: "Lucknow",
    links: "lucknow",
  },
  {
    text: "Ludhiana",
    links: "ludhiana",
  },
  {
    text: "Machilipatnam",
    links: "machilipatnam",
  },
  {
    text: "Madanapalle",
    links: "madanapalle",
  },
  {
    text: "Madhyamgram",
    links: "madhyamgram",
  },
  {
    text: "Madurai",
    links: "madurai",
  },
  {
    text: "Mahesana",
    links: "mahesana",
  },
  {
    text: "Maheshtala",
    links: "maheshtala",
  },
  {
    text: "Malegaon",
    links: "malegaon",
  },
  {
    text: "Mangalore",
    links: "mangalore",
  },
  {
    text: "Mango",
    links: "mango",
  },
  {
    text: "Mathura",
    links: "mathura",
  },
  {
    text: "Mau",
    links: "mau",
  },
  {
    text: "Meerut",
    links: "meerut",
  },
  {
    text: "Mira-Bhayandar",
    links: "mira-bhayandar",
  },
  {
    text: "Miryalaguda",
    links: "miryalaguda",
  },
  {
    text: "Mirzapur",
    links: "mirzapur",
  },
  {
    text: "Moradabad",
    links: "moradabad",
  },
  {
    text: "Morena",
    links: "morena",
  },
  {
    text: "Morvi",
    links: "morvi",
  },
  {
    text: "Motihari",
    links: "motihari",
  },
  {
    text: "Mumbai",
    links: "mumbai",
  },
  {
    text: "Munger",
    links: "munger",
  },
  {
    text: "Murwara",
    links: "murwara",
  },
  {
    text: "Muzaffarnagar",
    links: "muzaffarnagar",
  },
  {
    text: "Muzaffarpur",
    links: "muzaffarpur",
  },
  {
    text: "Mysore",
    links: "mysore",
  },
  {
    text: "Nadiad",
    links: "nadiad",
  },
  {
    text: "Nagarcoil",
    links: "nagarcoil",
  },
  {
    text: "Nagpur",
    links: "nagpur",
  },
  {
    text: "Naihati",
    links: "naihati",
  },
  {
    text: "Nanded",
    links: "nanded",
  },
  {
    text: "Nandyal",
    links: "nandyal",
  },
  {
    text: "Nangloi Jat",
    links: "nangloi-jat",
  },
  {
    text: "Narasaraopet",
    links: "narasaraopet",
  },
  {
    text: "Nashik",
    links: "nashik",
  },
  {
    text: "Navi Mumbai",
    links: "navi-mumbai",
  },
  {
    text: "Navi Mumbai Panvel Raigad",
    links: "navi-mumbai-panvel-raigad",
  },
  {
    text: "Nellore",
    links: "nellore",
  },
  {
    text: "New Delhi",
    links: "new-delhi",
  },
  {
    text: "Nizamabad",
    links: "nizamabad",
  },
  {
    text: "Noida",
    links: "noida",
  },
  {
    text: "North Dumdum",
    links: "north-dumdum",
  },
  {
    text: "Ongole",
    links: "ongole",
  },
  {
    text: "Orai",
    links: "orai",
  },
  {
    text: "Ozhukarai",
    links: "ozhukarai",
  },
  {
    text: "Pali",
    links: "pali",
  },
  {
    text: "Pallavaram",
    links: "pallavaram",
  },
  {
    text: "Panchkula",
    links: "panchkula",
  },
  {
    text: "Panihati",
    links: "panihati",
  },
  {
    text: "Panipat",
    links: "panipat",
  },
  {
    text: "Panvel",
    links: "panvel",
  },
  {
    text: "Parbhani",
    links: "parbhani",
  },
  {
    text: "Patiala",
    links: "patiala",
  },
  {
    text: "Patna",
    links: "patna",
  },
  {
    text: "Pimpri-Chinchwad",
    links: "pimpri-chinchwad",
  },
  {
    text: "Proddatur",
    links: "proddatur",
  },
  {
    text: "Puducherry",
    links: "puducherry",
  },
  {
    text: "Pune",
    links: "pune",
  },
  {
    text: "Purnia",
    links: "purnia",
  },
  {
    text: "Rae Bareli",
    links: "rae-bareli",
  },
  {
    text: "Raichur",
    links: "raichur",
  },
  {
    text: "Raiganj",
    links: "raiganj",
  },
  {
    text: "Raipur",
    links: "raipur",
  },
  {
    text: "Rajahmundry",
    links: "rajahmundry",
  },
  {
    text: "Rajkot",
    links: "rajkot",
  },
  {
    text: "Rajpur Sonarpur",
    links: "rajpur-sonarpur",
  },
  {
    text: "Ramagundam",
    links: "ramagundam",
  },
  {
    text: "Rampur",
    links: "rampur",
  },
  {
    text: "Ranchi",
    links: "ranchi",
  },
  {
    text: "Ratlam",
    links: "ratlam",
  },
  {
    text: "Raurkela Industrial Township",
    links: "raurkela-industrial-township",
  },
  {
    text: "Rewa",
    links: "rewa",
  },
  {
    text: "Rohtak",
    links: "rohtak",
  },
  {
    text: "Rourkela",
    links: "rourkela",
  },
  {
    text: "Sagar",
    links: "sagar",
  },
  {
    text: "Saharanpur",
    links: "saharanpur",
  },
  {
    text: "Saharsa",
    links: "saharsa",
  },
  {
    text: "Salem",
    links: "salem",
  },
  {
    text: "Sambalpur",
    links: "sambalpur",
  },
  {
    text: "Sambhal",
    links: "sambhal",
  },
  {
    text: "Sangli-Miraj and Kupwad",
    links: "sangli-miraj-and-kupwad",
  },
  {
    text: "Sasaram",
    links: "sasaram",
  },
  {
    text: "Satara",
    links: "satara",
  },
  {
    text: "Satna",
    links: "satna",
  },
  {
    text: "Secunderabad",
    links: "secunderabad",
  },
  {
    text: "Serampore",
    links: "serampore",
  },
  {
    text: "Shahjahanpur",
    links: "shahjahanpur",
  },
  {
    text: "Shimla",
    links: "shimla",
  },
  {
    text: "Shivamogga",
    links: "shivamogga",
  },
  {
    text: "Shivpuri",
    links: "shivpuri",
  },
  {
    text: "Sikar",
    links: "sikar",
  },
  {
    text: "Siliguri",
    links: "siliguri",
  },
  {
    text: "Singrauli",
    links: "singrauli",
  },
  {
    text: "Sirsa",
    links: "sirsa",
  },
  {
    text: "Siwan",
    links: "siwan",
  },
  {
    text: "Solapur",
    links: "solapur",
  },
  {
    text: "Sonipat",
    links: "sonipat",
  },
  {
    text: "South Dumdum",
    links: "south-dumdum",
  },
  {
    text: "Sri Ganganagar",
    links: "sri-ganganagar",
  },
  {
    text: "Srikakulam",
    links: "srikakulam",
  },
  {
    text: "Srinagar",
    links: "srinagar",
  },
  {
    text: "Sultan Pur Majra",
    links: "sultan-pur-majra",
  },
  {
    text: "Surat",
    links: "surat",
  },
  {
    text: "Surendranagar Dudhrej",
    links: "surendranagar-dudhrej",
  },
  {
    text: "Suryapet",
    links: "suryapet",
  },
  {
    text: "Tadepalligudem",
    links: "tadepalligudem",
  },
  {
    text: "Tadipatri",
    links: "tadipatri",
  },
  {
    text: "Tenali",
    links: "tenali",
  },
  {
    text: "Thane",
    links: "thane",
  },
  {
    text: "Thanjavur",
    links: "thanjavur",
  },
  {
    text: "Thiruvananthapuram",
    links: "thiruvananthapuram",
  },
  {
    text: "Thoothukudi",
    links: "thoothukudi",
  },
  {
    text: "Thrissur",
    links: "thrissur",
  },
  {
    text: "Tiruchirappalli",
    links: "tiruchirappalli",
  },
  {
    text: "Tirunelveli",
    links: "tirunelveli",
  },
  {
    text: "Tirupati",
    links: "tirupati",
  },
  {
    text: "Tiruppur",
    links: "tiruppur",
  },
  {
    text: "Tiruvottiyur",
    links: "tiruvottiyur",
  },
  {
    text: "Tumkur",
    links: "tumkur",
  },
  {
    text: "Udaipur",
    links: "udaipur",
  },
  {
    text: "Ujjain",
    links: "ujjain",
  },
  {
    text: "Ulhasnagar",
    links: "ulhasnagar",
  },
  {
    text: "Uluberia",
    links: "uluberia",
  },
  {
    text: "Unnao",
    links: "unnao",
  },
  {
    text: "Vadodara",
    links: "vadodara",
  },
  {
    text: "Varanasi",
    links: "varanasi",
  },
  {
    text: "Vasai-Virar",
    links: "vasai-virar",
  },
  {
    text: "Vellore",
    links: "vellore",
  },
  {
    text: "Vijayanagaram",
    links: "vijayanagaram",
  },
  {
    text: "Vijayawada",
    links: "vijayawada",
  },
  {
    text: "Visakhapatnam",
    links: "visakhapatnam",
  },
  {
    text: "Warangal",
    links: "warangal",
  },
  {
    text: "Yamunanagar",
    links: "yamunanagar",
  },
];

export {
  smDetails,
  pricingDetails,
  faqDetails,
  footerMenu,
  testimonialsDetails,
  bestFeaturesDetails,
  craftedBusinessDetails,
  choosingBillChampDetails,
  footerTags,
  cityLinks,
};
