import { Typography } from "@mui/material";
import React from "react";
import theme from "../../Theme";

const HeaderTitle = ({ title, highlight, varient, pb, align }) => {
  const boldSection = highlight; // Specify the section you want to be bolded here
  const [firstPart, secondPart] = title.split(boldSection);
  return (
    <React.Fragment>
      <Typography
        variant={varient}
        pb={pb}
        textAlign={align}
        sx={{ textWrap: "balance" }}
      >
        {firstPart}
        <span style={{ color: theme.palette.primary.main }}>{highlight}</span>
        {secondPart}
      </Typography>
    </React.Fragment>
  );
};

HeaderTitle.defaultProps = {
  title: "add the title",
  varient: "h4",
  pb: 2,
  align: "left",
};

export default HeaderTitle;
