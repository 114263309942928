import React from "react";
import DefaultLayout from "../Components/Layout/DefaultLayout";
import { Box, Container, Typography } from "@mui/material";
import { ListTemp } from "./TermsConditions";
import theme from "../Theme";
const CancellationRefundPolicy = () => {
  return (
    <React.Fragment>
      <DefaultLayout>
        <Box>
          <Container>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                " & h3": {
                  textDecoration: "underline",
                  textAlign: "center",
                  mb: 3,
                  fontSize: { xs: 20, sm: 22, md: 28, lg: 28 },
                },
                " & div ": {
                  display: "flex",
                  gap: 1,
                  alignItems: "start",

                  mb: 0.3,
                },
                " & h4": {
                  fontWeight: "450",
                  mb: 3,
                  fontSize: { xs: 18, sm: 22, lg: 28 },
                },
                " & p": {
                  fontSize: "16px",
                  // fontFamily: "DM Sans",
                  lineHeight: 1.6,
                  marginBottom: "10px",
                  color: "#736e63",
                  fontWeight: "400",
                  fontSize: { xs: 14, sm: 16, lg: 18 },
                },
                " & h5": {
                  // fontFamily: "DM Sans",
                  fontWeight: "bold",
                  color: theme.palette.secondary.main,
                  fontSize: { xs: 16, sm: 18 },
                  mb: 1.2,
                },
                " & ol": {
                  "& li": {
                    fontSize: "18px",
                    color: "#736e63",
                    lineHeight: 1.6,
                    fontWeight: 400,
                    marginLeft: "10px",
                    // fontFamily: "DM Sans",
                    listStyleType: "upper-Roman",
                    mb: 0.5,
                    fontSize: { xs: 14, sm: 18, md: 18 },
                  },
                },
                " & ul": {
                  "& li": {
                    // fontSize: "18px",
                    color: "#736e63",
                    lineHeight: 1.6,
                    fontWeight: 400,
                    // fontFamily: "DM Sans",
                    listStyleType: "disc",
                    mb: 0.5,
                    fontSize: { xs: 14, sm: 18 },
                  },
                },
                " & h4": {
                  fontWeight: 500,
                  mb: 1,
                  fontSize: { xs: 14, sm: 18, lg: 20 },
                },
                " & u": {
                  fontWeight: 500,
                  color: "black",
                },
                " & h6": {
                  fontWeight: 550,
                  fontSize: { xs: 14, sm: 16, lg: 18 },
                },
              }}
            >
              <Typography variant="h3">CANCELLATION & REFUND POLICY</Typography>

              <Typography variant="h6">
                (FOR OUTSIDE INDIA CUSTOMERS)
              </Typography>

              <Typography variant="h4">
                Last Updated : October 12, 2023
              </Typography>
              <ListTemp listData='We, Tektree Software Services Private Limited ("We," "Us," "Our," “Company”), are engaged in the business of providing a cloud-based point of sale software application under the brand name “BillChamp” for restaurants, along with optional integrated services such as mobile or card payments, inventory management, deals and discounts, ordering and delivery apps, table booking apps, suppliers’ marketplace, analytics apps, etc., using a programmable API architecture. The Company offers end-to-end integration of services for restaurant management to various restaurants and food outlets through the BillChamp Platforms. The Company has developed and is the owner of the BillChamp Platforms and offers licenses for their use through various subscription models for a Subscription Fee.' />
              <ListTemp listData='The subscription to the BillChamp POS Platform and other value-added services ("BillChamp Subscription") provided by us is subject to our Terms of Service, which you agree to (or anyone accessing and using the BillChamp Platforms shall be referred to as “You,” “Your,” or “User” as context dictates) at the time of subscription. You can choose a BillChamp subscription plan that suits your needs and make payments using the available payment methods for different BillChamp subscription plans.' />
              <Typography variant="h5">Fees</Typography>
              <ListTemp listData='You are obligated to remit the subscription fee in accordance with the BillChamp Subscription plan you have selected ("Subscription Fee"). The Subscription Fee, as detailed in various subscription plans, is non-refundable, unless explicitly defined in our cancellation and refund terms ("Cancellation & Refund Policy"). Taxes may be applicable to the subscription fee. Your paid BillChamp Subscription will be activated only after the successful payment of the Subscription Fee through the BillChamp Platforms, in line with your chosen subscription plan.' />
              <Typography variant="h5">
                Subscription Cancellation & Refund
              </Typography>
              <ListTemp
                listData="You can terminate your paid BillChamp Subscription at any time by accessing Your Account and adjusting your subscription preferences on any of the BillChamp Platforms. If you cancel your BillChamp Subscription within 15 business days of initial payment, we will issue a credit note and fully refund your Subscription Fee. However, we may deduct the value of BillChamp benefits you've utilized during this 15-day period, and issue a partial credit note accordingly. If you cancel the annual BillChamp Subscription at any other time, we will issue a credit note and refund your Subscription Fee based on your usage of the subscription plan on our BillChamp Platform since the initial payment. Please note that refunds will be processed 15 days after approval.
"
              />
              <ListTemp listData="For monthly BillChamp Subscriptions, a refund of the subscription fee will be processed only if you haven't utilized any BillChamp benefits since your Subscription Fee payment." />
              <Typography variant="h5">Modifications</Typography>
              <ListTemp listData="We reserve the right to modify the Terms of Service and this Cancellation & Refund Policy, or any part of the BillChamp Subscription, at our discretion, without prior notice. If any alteration to these terms is deemed invalid, void, or unenforceable for any reason, it will not affect the validity and enforceability of the remaining changes or conditions. YOUR ONGOING SUBSCRIPTION FOLLOWING ANY REVISIONS INDICATES YOUR ACCEPTANCE OF SUCH MODIFICATIONS. IF YOU DISAGREE WITH ANY AMENDMENTS, YOU MUST CANCEL YOUR SUBSCRIPTION." />
              <Typography variant="h5">Termination by BillChamp</Typography>
              <ListTemp listData="We reserve the right to terminate your BillChamp Subscription at our discretion without prior notice. In such cases, we will issue a credit note and provide a prorated refund based on the remaining duration of your subscription term, whether it's annual or monthly. However, no refund will be given for terminations resulting from actions that we, at our discretion, determine to be in violation of the Terms of Service or any applicable laws, involving fraud or misuse of the BillChamp Subscription, or being detrimental to our interests or the interests of another user. Our decision not to insist on or enforce strict compliance with the Terms of Service or this Cancellation & Refund Policy will not constitute a waiver of any of our rights." />
              <Typography variant="h5">Limitation of Liability</Typography>
              <ListTemp listData="In addition to other limitations and exclusions stated in the Conditions of Use and Sale, we, our directors, officers, employees, agents, and other representatives shall not be liable for any direct, indirect, special, incidental, consequential, or punitive damages or any other damages arising out of or related to the use of BillChamp Platforms. Our total liability, whether in contract, warranty, tort (including negligence), or otherwise, will not exceed the last subscription fee you paid. These exclusions and limitations of liability will apply to the fullest extent permitted by law and will remain in effect after the cancellation or termination of your BillChamp Subscription." />
            </Box>
          </Container>
        </Box>
      </DefaultLayout>
    </React.Fragment>
  );
};

export default CancellationRefundPolicy;
