import React from "react";

//MUI
import { Avatar, Box, Container, Typography, Tooltip } from "@mui/material";

//Images and Icons
import Billing1 from "../../Assets/Images/billing1.jpg";
import Billing2 from "../../Assets/Images/billing2.jpg";
import theme from "../../Theme";
const OurPosSoftwareTemplate = (props) => {
  const { title, text, icon } = props.data; // Destructure the data prop

  return (
    <React.Fragment>
      <Tooltip title={text} placement="top" arrow>
        <Box display={"flex"} gap={1} mb={3}>
          <Box width={"30px"}>{icon}</Box>
          <Box>
            <Typography color={theme.palette.primary.main}>{title}</Typography>
            {/* <Typography>{text}</Typography> */}
          </Box>
        </Box>
      </Tooltip>
    </React.Fragment>
  );
};

const OurPosSoftware = ({ data }) => {
  const halfLength = Math.ceil(data.length / 2);
  const firstHalf = data.slice(0, halfLength);
  const secondHalf = data.slice(halfLength);
  return (
    <React.Fragment>
      <Box
        py={5}
        color={theme.palette.grey[100]}
        bgcolor={theme.palette.secondary.main}
      >
        <Container>
          <Typography variant="h3" textAlign={"center"}>
            Our POS software is precisely crafted for all business
          </Typography>
          <Box
            display={"flex"}
            my={5}
            gap={4}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
          >
            <Box
              flex={1}
              bgcolor={theme.palette.grey[100]}
              borderRadius={5}
              display={"flex"}
              gap={1}
              sx={{ flexDirection: { xs: "column", sm: "row" } }}
            >
              <Box
                flex={1}
                sx={{
                  backgroundImage: `url(${Billing2})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  borderTopLeftRadius: "inherit",
                  borderBottomLeftRadius: { xs: "0px", sm: "inherit" },
                  borderTopRightRadius: { xs: "inherit", sm: "0px" },
                  height: { xs: "90vw", sm: "auto" },
                  maxHeight: { xs: "400px", sm: "auto" },
                  minHeight: { xs: "400px", sm: "auto" },
                }}
              ></Box>
              <Box
                flex={1}
                color={"InfoText"}
                display={"flex"}
                flexDirection={"column"}
              >
                <Box p={3}>
                  {firstHalf.map((industry, index) => (
                    <OurPosSoftwareTemplate key={index} data={industry} />
                  ))}
                </Box>
              </Box>
            </Box>
            <Box
              flex={1}
              bgcolor={theme.palette.grey[100]}
              borderRadius={5}
              display={"flex"}
              gap={1}
              sx={{ flexDirection: { xs: "column", sm: "row" } }}
            >
              <Box
                flex={1}
                sx={{
                  backgroundImage: `url(${Billing1})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  borderTopLeftRadius: "inherit",
                  borderBottomLeftRadius: { xs: "0px", sm: "inherit" },
                  borderTopRightRadius: { xs: "inherit", sm: "0px" },
                  height: { xs: "90vw", sm: "auto" },
                  maxHeight: { xs: "400px", sm: "auto" },
                  minHeight: { xs: "400px", sm: "auto" },
                }}
              >
                {/* <img
                  src={Billing1}
                  style={{
                    objectFit: "cover",
                    maxWidth: "100%",
                    maxHeight: "100%",

                    float: "left",
                    borderTopLeftRadius: 20,
                    borderBottomLeftRadius: 20,
                  }}
                /> */}
              </Box>
              <Box
                flex={1}
                color={theme.palette.grey[100]}
                display={"flex"}
                flexDirection={"column"}
              >
                <Box p={3} height={"100%"} boxSizing={"border-box"}>
                  {secondHalf.map((industry, index) => (
                    <OurPosSoftwareTemplate key={index} data={industry} />
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            bgcolor={theme.palette.grey[100]}
            borderRadius={2}
            color={theme.palette.text.primary}
            py={2}
          >
            <Typography textAlign={"center"} sx={{ textWrap: "balance" }}>
              Struggling with outdated inventory methods? Get started with
              BillChamp, efficient, precise, and budget-friendly software for
              future-proof inventory control.
            </Typography>
          </Box>
        </Container>
      </Box>
    </React.Fragment>
  );
};

OurPosSoftwareTemplate.defaultProps = {
  text: "add the text",
  profilepik: "/static/images/avatar/1.jpg",
  name: "name",
  designation: "designation",
};

export default OurPosSoftware;
