import React from "react";
import Header from "../Sections/Header";
import Banner from "../Sections/Banner";
import { Box, Container } from "@mui/material";
import Footer from "../Sections/Footer";
import { motion, useScroll } from "framer-motion";
import theme from "../../Theme";

const DefaultLayout = ({ children, ...props }) => {
  const { scrollYProgress } = useScroll();
  return (
    <React.Fragment>
      <Box
        sx={{
          background:
            "linear-gradient(156deg, rgba(0, 175, 18, 0.20) -25.41%, rgba(255, 139, 123, 0.00) 49.5%, rgba(255, 124, 63, 0.01) 52.18%), linear-gradient(200deg, rgba(63, 120, 224, 0.3) -25.18%, rgba(255, 124, 63, 0.3) 25.18%, rgba(255, 124, 63, 0.01) 52.18%)",
          marginBottom: "50px",
        }}
      >
        <Container>
          <motion.div
            style={{
              scaleX: scrollYProgress,
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              height: "3px",
              background: theme.palette.secondary.main,
              transformOrigin: "0%",
              zIndex: 999,
            }}
          />
          <Header />
          {props.homebanner ? <Banner /> : null}
        </Container>
      </Box>
      {children}
      <Footer />
    </React.Fragment>
  );
};

export default DefaultLayout;
