import React, { useState } from "react";

//MUI
import {
  Button,
  Container,
  Typography,
  Grid,
  Box,
  Modal,
  Input,
  TextField,
} from "@mui/material";
import theme from "../../Theme";

import axios from "axios";
import { toast } from "react-toastify";

//Framer Motion
import { motion, AnimatePresence } from "framer-motion";

//Cuttom Components
import HeaderTitle from "../Helper/HeaderTitle";

//Images and Icons
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

const Banner = () => {
  const [open, setOpen] = useState(false); //Get a demo Modal
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [storeName, setStoreName] = useState("");
  const [personName, setPersonName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [mobile, setMobile] = useState("");
  const [response, setResponse] = useState(null);

  const handleTryFreeSubmit = () => {
    const websiteLead = {
      storeName,
      personName,
      emailId,
      mobile,
      type: "Try Free",
    };
    console.log("Form submit: ", websiteLead);
    const api = axios.create({
      baseURL: "https://api.billchamppos.com:9000",
    });

    console.log("Form submit: ", websiteLead);

    // Use the axios instance to make the POST request
    api
      .post("/websiteLeads", websiteLead)
      .then((response) => {
        setResponse(response.data);
        setOpen(false);
        toast("Form submitted successfully! Our team will contact you soon.", {
          position: "top-right",
          autoClose: false,
          hideProgressBar: false,
          className: "bg-success text-white",
        });
      })
      .catch((error) => {
        console.error("Error creating data:", error);
        toast(
          "Form submission failed! Please contact administrator: 93 90 11 48 45",
          {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            className: "bg-danger text-white",
          }
        );
      });
  };

  return (
    <React.Fragment>
      <Container>
        <Grid container>
          <Grid item xs={12} md={7}>
            <dotlottie-player
              src="https://lottie.host/1eccecaa-1585-4019-ba05-95c1179b2197/KXKBpKg8oS.lottie"
              background="transparent"
              speed="2"
              class="pos-image"
              direction="1"
              mode="normal"
              loop
              autoplay
            ></dotlottie-player>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              height={"100%"}
              gap={2}
            >
              <HeaderTitle
                title={
                  "Restaurant management and billing made easy with BillChamp."
                }
                highlight={"easy with BillChamp"}
                varient={"h3"}
                pb={0}
              />

              <Typography>
                Affordable and super quick to setup in just 2 minutes!
              </Typography>
              <Box mt={1}>
                <Button
                  variant="outlined"
                  color={"secondary"}
                  size="large"
                  startIcon={<PlayArrowIcon />}
                  onClick={() => {window.open("https://billing.billchamppos.com/register", "_blank");}}
                  sx={{ textTransform: "inherit" }}
                >
                  Try for free!
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: 600,
            backgroundColor: "rgba(255,255,255,1)",
            boxShadow: 24,
            p: 4,

            backdropFilter: "blur(30px)",
          }}
        >
          <Typography variant="h4" textAlign={"center"}>
            Try for free!
          </Typography>
          <form>
            <Box display={"flex"} gap={2} flexDirection={"column"} marginY={3}>
              <Box display={"flex"} gap={2}>
                <TextField
                  id="outlined-basic"
                  name="storeName"
                  label="Business Name"
                  variant="outlined"
                  fullWidth
                  value={storeName}
                  onChange={(e) => setStoreName(e.target.value)}
                />
                <TextField
                  id="outlined-basic"
                  name="personName"
                  label="Person Name"
                  variant="outlined"
                  fullWidth
                  value={personName}
                  onChange={(e) => setPersonName(e.target.value)}
                />
              </Box>
              <Box display={"flex"} gap={2}>
                <TextField
                  id="outlined-basic"
                  name="emailId"
                  label="EmailId"
                  variant="outlined"
                  fullWidth
                  value={emailId}
                  onChange={(e) => setEmailId(e.target.value)}
                />
                <TextField
                  id="outlined-basic"
                  name="mobile"
                  label="Phone Number"
                  variant="outlined"
                  fullWidth
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </Box>
            </Box>
            <Box display={"flex"} justifyContent={"center"} gap={3}>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={handleTryFreeSubmit}
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default Banner;
