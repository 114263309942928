import React, { useState, useEffect } from "react";
import DefaultLayout from "../Components/Layout/DefaultLayout";

import { Helmet, HelmetProvider } from "react-helmet-async";

//Custom Components
import ChoosingBillChamp from "../Components/Sections/ChoosingBillChamp";
import OurPosSoftware from "../Components/Sections/OurPosSoftware";
import BestFeatures from "../Components/Sections/BestFeatures";
import TestimonialBlock from "../Components/Sections/TestimonialBlock";
import Pricing from "../Components/Sections/Pricing";
import Faq from "../Components/Sections/Faq";
import axios from "axios";
import { toast } from "react-toastify";

//Data
import {
  choosingBillChampDetails,
  bestFeaturesDetails,
  testimonialsDetails,
  craftedBusinessDetails,
} from "../Common/data";
import { Box, Modal, Button, TextField, Typography } from "@mui/material";
import JoinCommunity from "../Components/Sections/JoinCommunity";

//import ogImage from "../Assets/Images/features.png";
//const ogImage = `${process.env.PUBLIC_URL}/Assets/Images/features.png`;
const ogImageUrl = "https://www.billchamppos.com/smi.png";

const Home = () => {
  const [openContactModal, setOpenContactModal] = useState(false); //Get a demo Modal
  const handleOpenContactModal = () => {
    setOpenContactModal(true);
  };
  const handleCloseContactModal = () => {
    setOpenContactModal(false);
  };

  ///Page TITLE and Other SEO

  const [storeName, setStoreName] = useState("");
  const [personName, setPersonName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [mobile, setMobile] = useState("");

  const [response, setResponse] = useState(null);
  const handleContactUsSubmit = () => {
    const websiteLead = {
      storeName,
      personName,
      emailId,
      mobile,
      type: "Contact Us",
    };
    console.log("Form submit: ", websiteLead);
    const api = axios.create({
      baseURL: "https://api.billchamppos.com:9000",
    });

    console.log("Form submit: ", websiteLead);

    // Use the axios instance to make the POST request
    api
      .post("/websiteLeads", websiteLead)
      .then((response) => {
        console.log("Response here: ", response);
        setResponse(response.data);
        toast("Form submitted successfully! Our team will contact you soon.", {
          position: "top-right",
          autoClose: false,
          hideProgressBar: false,
          className: "bg-success text-white",
        });
        setOpenContactModal(false);
      })
      .catch((error) => {
        console.error("Error creating data:", error);
        toast(
          "Form submission failed! Please contact administrator: 93 90 11 48 45",
          {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            className: "bg-danger text-white",
          }
        );
      });
  };

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet>
          <title>Restaurant POS & Management System by BillChamp</title>
          <meta
            name="description"
            content="Restaurant POS Software for efficient management. Boost profits with our Restaurant Point of Sale & Inventory System. The future of restaurant operations!"
          />

          <meta property="og:url" content="https://billchamppos.com" />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="Restaurant POS & Management System by BillChamp"
          />
          <meta
            property="og:description"
            content="Restaurant POS Software for efficient management. Boost profits with our Restaurant Management and Inventory System. Discover the future of restaurant operations today"
          />

          <meta
            property="og:image"
            content="https://www.billchamppos.com/smi.png"
          ></meta>
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="billchamppos.com" />
          <meta
            property="twitter:url"
            content="https://twitter.com/billchamppos"
          />
          <meta
            name="twitter:title"
            content="Restaurant POS & Management System by BillChamp"
          />
          <meta
            name="twitter:description"
            content="Restaurant POS Software for efficient management. Boost profits with our Restaurant Management and Inventory System. Discover the future of restaurant operations today"
          />
          <meta
            name="twitter:image"
            content="https://www.billchamppos.com/smi.png"
          ></meta>
        </Helmet>
        <DefaultLayout homebanner>
          <ChoosingBillChamp
            data={choosingBillChampDetails}
            contactClick={handleOpenContactModal}
          />
          <OurPosSoftware data={craftedBusinessDetails} />
          <BestFeatures data={bestFeaturesDetails} />
          <TestimonialBlock data={testimonialsDetails} />
          <Pricing contactClick={handleOpenContactModal} />
          <Faq />
          <JoinCommunity />
        </DefaultLayout>

        <Modal
          open={openContactModal}
          onClose={handleCloseContactModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          disableBackdropClick={true}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "100%",
              maxWidth: 600,
              backgroundColor: "rgba(255,255,255,1)",
              boxShadow: 24,
              p: 4,

              backdropFilter: "blur(30px)",
            }}
          >
            <Typography variant="h4" textAlign={"center"}>
              Contact Us
            </Typography>
            <form>
              <Box
                display={"flex"}
                gap={2}
                flexDirection={"column"}
                marginY={3}
              >
                <Box display={"flex"} gap={2}>
                  <TextField
                    id="outlined-basic"
                    name="storeName"
                    label="Business Name"
                    variant="outlined"
                    fullWidth
                    value={storeName}
                    onChange={(e) => setStoreName(e.target.value)}
                  />
                  <TextField
                    id="outlined-basic"
                    name="personName"
                    label="Person Name"
                    variant="outlined"
                    fullWidth
                    value={personName}
                    onChange={(e) => setPersonName(e.target.value)}
                  />
                </Box>
                <Box display={"flex"} gap={2}>
                  <TextField
                    id="outlined-basic"
                    name="emailId"
                    label="EmailId"
                    variant="outlined"
                    fullWidth
                    value={emailId}
                    onChange={(e) => setEmailId(e.target.value)}
                  />
                  <TextField
                    id="outlined-basic"
                    name="mobile"
                    label="Phone Number"
                    variant="outlined"
                    fullWidth
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                  />
                </Box>
              </Box>
              <Box display={"flex"} justifyContent={"center"} gap={3}>
                <Button onClick={handleCloseContactModal}>Cancel</Button>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={handleContactUsSubmit}
                >
                  Submit
                </Button>
              </Box>
            </form>
          </Box>
        </Modal>
      </HelmetProvider>
    </React.Fragment>
  );
};

export default Home;
